<template>
  <v-data-table
    :headers="headers"
    :items="notificaciones"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Notificaciones</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Nueva notificación
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="8" md="12">
                    <v-text-field
                      v-model="editedItem.titulo"
                      label="Título"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8" md="12">
                    <v-text-field
                      v-model="editedItem.notificacion"
                      label="Notificación"
                    ></v-text-field>
                  </v-col>

                  <v-col class="d-flex" cols="12" md="12" sm="6">
                    <v-select
                      v-model="tipo"
                      :items="tipos"
                      label="Tipo de notificación"
                      item-text="tipo"
                      item-value="valor"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >¿Estás seguro de eliminar este elemento?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    tipo: null,
    tipos: [
      { tipo: "General", valor: 1 },
      { tipo: "Curso", valor: 2 },
      { tipo: "Departamento", valor: 3 },
      { tipo: "Empleado", valor: 4 },
      { tipo: "Capacitador", valor: 5 },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "idnotificacion",
      },
      { text: "Título", value: "titulo" },
      { text: "Notificacion", value: "notificacion" },
      { text: "Tipo", value: "tipo_notificacion" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    notificaciones: [], //arreglo
    editedIndex: -1,
    editedItem: {
      //agrego cada campo a utlizar
      titulo: "",
      idnotificacion: 0,
      tipo_notificacion: 0,
      general: 0,
      departamento: 0,
      curso: 0,
      empleado: 0,
      capacitador: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    defaultItem: {
      titulo: "",
      idnotificacion: 0,
      tipo_notificacion: 0,
      general: 0,
      departamento: 0,
      curso: 0,
      empleado: 0,
      capacitador: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nueva notificación"
        : "Editar notificación";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    // Generando la fecha de hoy
  },

  methods: {
    initialize() {
      this.$http
        .get("notificaciones.all")
        .then((responseNotificaciones) => {
          this.notificaciones = responseNotificaciones.body;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.notificaciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.notificaciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.notificaciones.splice(this.editedIndex, 1);
      var datos = {
        titulo: this.editedItem.titulo,
        notificacion: this.editedItem.notificacion,
        tipo_notificacion: this.editedItem.tipo_notificacion,
        usuario_registro: 1,
        deleted: 1,
      };

      this.$http
        .put("notificaciones.update/" + this.editedItem.idnotificacion, datos)
        .then((response) => {
          this.initialize();
        })
        .catch((error) => console.log(error));
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      //guardo en una variable los datos a guardar
      console.log(this.tipo);
      var datos = {
        titulo: this.editedItem.titulo,
        notificacion: this.editedItem.notificacion,
        tipo_notificacion: this.tipo,
        usuario_registro: 1,
        deleted: 0,
      };

      if (this.editedIndex > -1) {
        this.$http
          .put("notificaciones.update/" + this.editedItem.idnotificacion, datos)
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));
        Object.assign(this.notificaciones[this.editedIndex], this.editedItem);
      } else {
        this.$http
          .post("notificaciones.add", datos)
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));

        this.notificaciones.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
