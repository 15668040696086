<template>
  <v-data-table
    :headers="headers"
    :items="subtema_empleado"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Subtema Empleado</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Nuevo subtema empleado
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="8" md="9">
                    <v-text-field
                      v-model="editedItem.estatus"
                      label="Estatus"
                    ></v-text-field>
                  </v-col>
                  <!-- temas add -->
                  <v-col cols="12" sm="8" md="8">
                    <v-autocomplete
                      v-model="subtema"
                      :items="subtemas"
                      label="Selecciona un subtema"
                      persistent-hint
                      prepend-inner-icon="mdi-text-box"
                      hide-details
                      return-object
                      clearable
                      item-text="subtema"
                      item-value="idsubtema"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="8" md="8">
                    <v-autocomplete
                      v-model="empleado"
                      :items="empleados"
                      label="Selecciona un empleado"
                      persistent-hint
                      prepend-inner-icon="mdi-text-box"
                      hide-details
                      return-object
                      clearable
                      item-text="nombre_completo"
                      item-value="id_usuario"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >¿Estás seguro de eliminar este elemento?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    subtema: null,
    subtemas: [],
    empleado: null,
    empleados: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "idsubtema_empleado",
      },
      { text: "Estatus", value: "estatus" },
      { text: "Subtema", value: "idsubtema" },
      { text: "Empleado", value: "idempleado" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    subtema_empleado: [], //arreglo
    editedIndex: -1,
    editedItem: {
      //agrego cada campo a utlizar
      idsubtema_empleado: 0,
      estatus: 0,
      idsubtema: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    defaultItem: {
      idsubtema_empleado: 0,
      estatus: 0,
      idsubtema: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo subtema empleado"
        : "Editar subtema empleado";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    // Generando la fecha de hoy
  },

  methods: {
    initialize() {
      this.$http
        .get("subtema_empleado.all")
        .then((responseTipo) => {
          this.subtema_empleado = responseTipo.body;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$http
        .get("subtemas.all")
        .then((responseTipo) => {
          this.subtemas = responseTipo.body;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$http
        .get("usuarios.all.erp")
        .then((responseUsuarios) => {
          for (const i in responseUsuarios.body) {
            responseUsuarios.body[i].nombre_completo = responseUsuarios.body[
              i
            ].nombre_completo.toUpperCase();
          }
          this.empleados = responseUsuarios.body;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.subtema_empleado.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.subtema_empleado.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.subtema_empleado.splice(this.editedIndex, 1);
      //"Borrando"
      var datos = {
        estatus: this.editedItem.estatus,
        idempleado: this.editedItem.idempleado,
        idsubtema: this.editedItem.idsubtema,
        usuario_registro: 1,
        deleted: 1,
      };
      this.$http
        .put(
          "subtema_empleado.update/" + this.editedItem.idsubtema_empleado,
          datos
        )
        .then((response) => {
          this.initialize();
        })
        .catch((error) => console.log(error));

      console.log(this.editedItem.idsubtema_empleado + " borrar");
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      //recibe los campos del form
      console.log(this.editedItem);

      //guardo en una variable los datos a guardar id = this.editedItem.idcurso;

      var datos = {
        estatus: this.editedItem.estatus,
        idempleado: this.empleado.id_usuario,
        idsubtema: this.subtema.idsubtema,
        usuario_registro: 1,
        deleted: 0,
      };

      if (this.editedIndex > -1) {
        this.$http
          .put(
            "subtema_empleado.update/" + this.editedItem.idsubtema_empleado,
            datos
          )
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));
        Object.assign(this.subtema_empleado[this.editedIndex], this.editedItem);
      } else {
        this.$http
          .post("subtema_empleado.add", datos)
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));

        this.subtema_empleado.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
