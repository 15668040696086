<template>
  <v-container>
    <v-card class="shadowCard">
      <v-container>
        <v-btn
          class="mr-2"
          small
          icon
          color="primary" @click="goBack()"
        >
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </v-container>
      
      <v-tabs
        v-model="tab"
      >
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item><Materiales /> </v-tab-item>
        <v-tab-item><Evaluaciones /> </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script>
import Evaluaciones from "./Evaluaciones.vue";
import Materiales from "./Materiales.vue";

export default {
  components: {
    Evaluaciones,
    Materiales,
  },

  data() {
    return {
      tab: null,
      items: [{ tab: "Materiales" }, { tab: "Evaluaciones" }],
      goBack() {
        window.history.back();
      },
    };
  },
};
</script>
