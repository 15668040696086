import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

export default {
    namespaced: true,
    state: {
        idCurso: '',
        curso: {
            idcurso: 0,
            curso: "",
            idcapacitador: 0,
            capacitador: "",
            duracion: 0,
            descripcion: "",
            imagen: "",
            usuario_registro: 0,
            fecha_creacion: null,
            fecha_actualizo: null,
            deleted: 0,
        },
    },

    mutations: {
        ID_CURSO(state, idCurso) {
            state.idCurso = idCurso
        },
        CURSO(state, curso) {
            state.curso = curso
        }
    },

    actions: {
        guardarIdCurso({ commit, dispatch }, id) {
            commit('ID_CURSO', id)
            console.log(id + " Curso")
        },

        guardarCurso({ commit, dispatch }, item) {
            commit('CURSO', item)
        },


    },

    getters: {
        getIdCurso(state) {
            return state.idCurso
        },
        getCurso(state) {
            return state.curso
        },
    }
}