import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

export default {
    namespaced: true,
    state: {
        idSubtema: '',
    },

    mutations: {
        ID_SUBTEMA(state, idSubtema) {
            state.idSubtema = idSubtema
        },
    },

    actions: {
        guardarIdSubtema({ commit, dispatch }, id) {
            commit('ID_SUBTEMA', id)
            console.log(id + " Subtema")
        },
    },

    getters: {
        getIdSubtema(state) {
            return state.idSubtema
        },
    }
}