var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.parametros.estatus),callback:function ($$v) {_vm.$set(_vm.parametros, "estatus", $$v)},expression:"parametros.estatus"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto shadowCard"},[_c('v-card-title',{staticClass:"text-h6 font-weight-regular justify-space-between"},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(" Cursos ")])]),_c('v-card-text',[(_vm.parametros.cursos)?_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"headers":_vm.headers,"items":_vm.parametros.cursos,"dense":"","multi-sort":"","show-expand":"","expanded":_vm.expanded,"single-expand":true,"item-key":"curso"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.calificacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.calificacion.toFixed( 2 ))+" ")]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [(item.estatus)?_c('v-chip',{attrs:{"color":"success","dark":"","small":""}},[_vm._v("Completado")]):_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("Pendiente")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"elevation-0 custom-card ma-1",attrs:{"width":"100%"}},[_c('v-card-title',{staticClass:"text-subtitle-1 pb-0",attrs:{"primary-title":""}},[_vm._v(" Calificaciones por evaluación ")]),_c('v-card-text',{staticClass:"pt-0 pl-0 pr-0"},[(item.evaluaciones.length)?_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"headers":_vm.headers2,"items":item.evaluaciones,"dense":"","hide-default-footer":"","fixed-header":"","items-per-page":item.evaluaciones.length},scopedSlots:_vm._u([{key:"item.calificacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.calificacion.toFixed( 2 ))+" ")]}}],null,true)}):_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" Sin evaluaciones ")])],1)],1)],1)]}}],null,false,3460448700)}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.parametros.estatus = false}}},[_vm._v("Cerrar")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }