<template>
  <v-container>
    <v-card class="shadowCard">
      <v-card-title primary-title>
        <v-btn
          class="mr-2"
          small
          icon
          color="primary" @click="goBack()"
        >
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>

        Subtemas
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="dialog = true" small>
          Nuevo subtema
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row justify="end">
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              filled
              hide-details
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="subtemas"
          sort-by="calories"
          class="elevation-0"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="800px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form ref="form" lazy-validation>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="9">
                          <v-text-field
                            :rules="rules"
                            v-model="editedItem.subtema"
                            label="Subtema"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                          <v-text-field
                            :rules="rules"
                            v-model="editedItem.duracion"
                            label="Duración (min)"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-btn color="blue darken-1" text @click="reset">
                    Limpiar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >¿Estás seguro de eliminar este elemento?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="openView(item)"
                >
                  <v-icon small>
                    mdi-plus-thick
                  </v-icon>
                </v-btn>
              </template>
              <span>Agregar recursos</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="editItem(item)"
                >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Editar subtema</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="deleteItem(item)"
                >
                  <v-icon small>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar subtema</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Actualizar
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

var moment = require("moment");
moment.locale();

export default {
  data: () => ({
    search:'',
    rules: [(value) => !!value || "Campo obligatorio."],
    tema: null,
    temas: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Subtema"      , value: "subtema" },
      { text: "Duración(min)", value: "duracion" },
      { text: "Tema"         , value: "tema" },
      { text: "Actions"      , value: "actions", sortable: false },
    ],
    subtemas: [], //arreglo
    editedIndex: -1,
    editedItem: {
      //agrego cada campo a utlizar
      idsubtema: 0,
      subtema: "",
      duracion: "",
      idtema: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    defaultItem: {
      idsubtema: 0,
      subtema: "",
      duracion: "",
      idtema: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo subtema" : "Editar subtema";
    },
    ...mapGetters("Temas", ["getIdTema"]),
    ...mapGetters("Subtemas", ["getIdSubtema"]),
    ...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    getIdTema() {
      this.initialize();
    },
  },

  created() {
    this.initialize();
    // Generando la fecha de hoy
    const fecha = new Date().toLocaleDateString("en-Es", {
      timeZone: "America/Mexico_City",
    });
    this.fecha = moment(fecha)
      .format()
      .substr(0, 10);
  },

  methods: {
    ...mapActions("Temas", ["guardarIdTema"]),
    ...mapActions("Subtemas", ["guardarIdSubtema"]),

    initialize() {
      this.temas = [];
      this.$http
        .get("temas.all")
        .then((responsetemas) => {
          this.temas = responsetemas.body;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$http
        .get("subtemas.temas.all/" + this.getIdTema)
        .then((responseTipo) => {
          this.subtemas = responseTipo.body;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openView(item) {
      this.guardarIdSubtema(item.idsubtema);
      this.$router.push({ name: "Recursos" });
    },
    goBack() {
      window.history.back();
    },
    editItem(item) {
      this.editedIndex = this.subtemas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.subtemas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.subtemas.splice(this.editedIndex, 1);
      //"Borrando"
      var datos = {
        subtema: this.editedItem.subtema,
        duracion: this.editedItem.duracion,
        idtema: this.editedItem.idtema,
        usuario_registro: this.getUsuarioCapa.idusuario,
        fecha_creacion: this.editedItem.fecha_creacion,
        fecha_actualizo: this.fecha,
        deleted: 1,
      };
      this.$http
        .put("subtemas.update/" + this.editedItem.idsubtema, datos)
        .then((response) => {
          this.initialize();
        })
        .catch((error) => console.log(error));

      console.log(this.editedItem.idsubtema + " borrar");
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      //recibe los campos del form
      console.log(this.editedItem);

      //guardo en una variable los datos a guardar id = this.editedItem.idcurso;

      var datos = {
        subtema: this.editedItem.subtema,
        duracion: this.editedItem.duracion,
        idtema: this.getIdTema,
        usuario_registro:
          this.editedItem.usuario_registro === 0
            ? this.getUsuarioCapa.idusuario
            : this.editedItem.usuario_registro,
        fecha_creacion:
          this.editedItem.fecha_creacion === null
            ? this.fecha
            : this.editedItem.fecha_creacion,
        fecha_actualizo: this.fecha,
        deleted: 0,
      };

      if (this.editedIndex > -1) {
        this.$http
          .put("subtemas.update/" + this.editedItem.idsubtema, datos)
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));
        Object.assign(this.subtemas[this.editedIndex], this.editedItem);
      } else {
        this.$http
          .post("subtemas.add", datos)
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));

        this.subtemas.push(this.editedItem);
      }
      this.close();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
