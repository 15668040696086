<template>
  <v-card class="mx-auto" flat>
    <v-container>
      <v-btn icon color="black" @click="goBack()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-card-title>{{ getEvaluacion.evaluacion }}</v-card-title>

      <v-card-text>
        <div>
          Instrucciones: Lee atentamente cada pregunta y elija la respuesta
          correcta.
        </div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-title>Preguntas</v-card-title>

      <v-radio-group v-for="p in preguntas" :key="p.idpregunta">
        <template v-slot:label>
          <v-card-text class="subtitle-1">
            <strong> {{ p.numero_pregunta + "." + " " + p.pregunta }}</strong>
          </v-card-text>
        </template>
        <v-radio :label="p.a" value="A"></v-radio>
        <v-radio :label="p.b" value="B"></v-radio>
        <v-radio :label="p.c" value="C"></v-radio>
        <v-radio :label="p.d" value="D"></v-radio>
      </v-radio-group>

      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="my-2">
          <v-btn color="primary" dark>
            Enviar respuestas
          </v-btn>
        </div>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
var moment = require("moment");
moment.locale();

import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    pregunta: null,
    preguntas: [],
    editedItem: {
      //agrego cada campo a utlizar
      idevaluacion: 0,
      evaluacion: "",
      idtipo_evaluacion: 0,
      idsubtema: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    defaultItem: {
      //agrego cada campo a utlizar
      idevaluacion: 0,
      evaluacion: "",
      idtipo_evaluacion: 0,
      idsubtema: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo curso" : "Editar curso";
    },

    ...mapGetters("Evaluaciones", ["getEvaluacion"]),
  },

  watch: {
    getEvaluacion() {
      this.initialize();
    },
    initialize() {
      this.initialize();
    },
  },

  created() {
    this.initialize();
    // Generando la fecha de hoy
    const fecha = new Date().toLocaleDateString("en-Es", {
      timeZone: "America/Mexico_City",
    });
    this.fecha = moment(fecha)
      .format()
      .substr(0, 10);

    //
  },

  methods: {
    initialize() {
      this.$http
        .get("preguntas.opcion.all/" + this.getEvaluacion.idevaluacion)
        .then((responsepreguntas) => {
          this.preguntas = responsepreguntas.body;
          console.log(this.preguntas);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>
