<template>
  <div>
    <v-card>
      <v-progress-linear
        class="mt-2"
        height="5"
        v-model="trackProgress"
        @click="updateSeek($event)"
      ></v-progress-linear>
      <!-- Info player -->
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title
            ><h3>{{ getMaterial.url.replace(".mp3", "") }}</h3>
          </v-card-title>
          <v-container>
            <h5 dense>
              {{ trackInfo.seek | minutes }}/{{ trackInfo.duration | minutes }}
            </h5>

            <v-card-actions>
              <v-btn
                v-if="play == false || getStatus == false"
                outlined
                fab
                small
                color="light-blue"
                @click="playTrack()"
              >
                <v-icon large>mdi-play</v-icon>
              </v-btn>
              <v-btn
                v-else
                outlined
                fab
                small
                color="light-blue"
                @click="pauseTrack()"
              >
                <v-icon large>mdi-pause</v-icon>
              </v-btn>
            </v-card-actions>
          </v-container>
        </div>

        <v-avatar class="ma-3" size="125" tile>
          <v-img :src="getCurso.imagen"></v-img>
        </v-avatar>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    loop: Boolean,
    shuffle: Boolean,
    progress: Number,
    trackInfo: Object,
  },
  data() {
    return {
      volume: 0.5,
      muted: false,
      play: false,
    };
  },
  watch: {
    play() {
      console.log("btn: " + this.play);
    },
  },

  computed: {
    trackProgress() {
      return this.progress * 100;
    },
    ...mapGetters("Materiales", ["getMaterial", "getStatus"]),
    ...mapGetters("Cursos", ["getCurso"]),

  },
  created: function() {
    Howler.volume(this.volume);
  },
  methods: {
    playTrack(index) {
      this.play = true;
      this.$emit("playtrack", index);
    },
    pauseTrack() {
      this.play = false;
      this.$emit("pausetrack");
    },
    stopTrack() {
      this.play = false;
      this.$emit("stoptrack");
    },

    updateSeek(event) {
      let el = document.querySelector(".progress-linear__bar"),
        mousePos = event.offsetX,
        elWidth = el.clientWidth,
        percents = (mousePos / elWidth) * 100;
      this.$emit("updateseek", percents);
    },
    ...mapActions("Materiales", ["guardarMaterial", "guardarStatus"]),
    ...mapActions("Cursos", ["guardarCurso"]),
  },
};
</script>
