import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

export default {
    namespaced: true,
    state: {
        idTema: '',
    },

    mutations: {
        ID_TEMA(state, idTema) {
            state.idTema = idTema
        },
    },

    actions: {
        guardarIdTema({ commit, dispatch }, id) {
            commit('ID_TEMA', id)
            console.log(id + " tema")
        },
    },

    getters: {
        getIdTema(state) {
            return state.idTema
        },
    }
}