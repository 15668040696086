import Vue from 'vue'
import Vuex         from 'vuex'
import Cursos       from '@/modules/Cursos.js'
import Temas        from '@/modules/Temas.js'
import Subtemas     from '@/modules/Subtemas.js'
import Evaluaciones from '@/modules/Evaluaciones.js'
import Preguntas    from '@/modules/Preguntas.js'
import Materiales   from '@/modules/Materiales.js'
import Audio        from '@/modules/Audio.js'
import UsuarioCapa  from '@/modules/usuariocapa'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Cursos,
    Temas,
    Subtemas,
    Evaluaciones,
    Preguntas,
    Materiales,
    UsuarioCapa,
    Audio,
  },
  plugins: [createPersistedState()]
})