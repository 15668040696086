import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import vuetify from '@/plugins/vuetify';

export default {
  namespaced: true,
  state: {
    loginCapa: false,
    datosUsuarioCapa: '',
  },

  mutations: {
    LOGEADO(state, value) {
      state.loginCapa = value
    },
    DATOS_USUARIO(state, datosUsuarioCapa) {
      state.datosUsuarioCapa = datosUsuarioCapa
    },

    SALIR(state) {
      state.loginCapa = false
      state.datosUsuarioCapa = ''
    }
  },

  actions: {
    guardarInfo({ commit, dispatch }, usuario) {
      commit('DATOS_USUARIO', usuario)
      commit('LOGEADO', true)
    },

    salirLogin({ commit }) {
      commit('SALIR')
    },
  },

  getters: {
    getLogeadoCapa(state) {
      return state.loginCapa
    },

    getUsuarioCapa(state) {
      return state.datosUsuarioCapa
    },
  }
}