<template>
  <v-data-table
    :headers="headers"
    :items="notificaciones_usuario"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Notificaciones usuario</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Nueva notificacion usuario
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="8" md="9">
                    <v-text-field
                      v-model="editedItem.estatus"
                      label="Estatus"
                    ></v-text-field>
                  </v-col>
                  <!-- not add -->
                  <v-col cols="12" sm="8" md="8">
                    <v-autocomplete
                      v-model="notificacion"
                      :items="notificaciones"
                      label="Selecciona una notficacion"
                      persistent-hint
                      prepend-inner-icon="mdi-bell"
                      hide-details
                      return-object
                      clearable
                      item-text="titulo"
                      item-value="idnotificacion"
                    >
                    </v-autocomplete>
                  </v-col>

                  <!-- subtema add -->
                  <v-col cols="12" sm="8" md="8">
                    <v-autocomplete
                      v-model="usuario"
                      :items="usuarios"
                      label="Selecciona un usuario"
                      persistent-hint
                      prepend-inner-icon="mdi-account"
                      hide-details
                      return-object
                      clearable
                      item-text="nombre_completo"
                      item-value="id_usuario"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >¿Estás seguro de eliminar este elemento?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    usuario: null,
    usuarios: [],
    notificacion: null,
    notificaciones: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "idnotificacion_usuario",
      },
      { text: "Estatus", value: "estatus" },
      { text: "Usuario", value: "idusuario" },
      { text: "Notificación", value: "idnotificacion" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    notificaciones_usuario: [], //arreglo
    editedIndex: -1,
    editedItem: {
      //agrego cada campo a utlizar
      idnotificacion_usuario: 0,
      estatus: 0,
      idnotificacion: 0,
      idsuario: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    defaultItem: {
      idnotificacion_usuario: 0,
      estatus: 0,
      idnotificacion: 0,
      idsuario: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nueva notificación usuario"
        : "Editar notificación usuario";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    // Generando la fecha de hoy
  },

  methods: {
    initialize() {
      this.$http
        .get("notificaciones_usuario.all")
        .then((responseTipo) => {
          this.notificaciones_usuario = responseTipo.body;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$http
        .get("notificaciones.all")
        .then((responseTipo) => {
          this.notificaciones = responseTipo.body;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$http
        .get("usuarios.all.erp")
        .then((responseUsuarios) => {
          for (const i in responseUsuarios.body) {
            responseUsuarios.body[i].nombre_completo = responseUsuarios.body[
              i
            ].nombre_completo.toUpperCase();
          }
          this.usuarios = responseUsuarios.body;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.notificaciones_usuario.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.notificaciones_usuario.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.notificaciones_usuario.splice(this.editedIndex, 1);
      var datos = {
        estatus: this.editedItem.estatus,
        idnotificacion: this.editedItem.idnotificacion,
        idusuario: this.editedItem.idusuario,
        usuario_registro: 1,
        deleted: 1,
      };

      this.$http
        .put(
          "notificaciones_usuario.update/" +
            this.editedItem.idnotificacion_usuario,
          datos
        )
        .then((response) => {
          this.initialize();
        })
        .catch((error) => console.log(error));
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      //recibe los campos del form
      console.log(this.editedItem);

      //guardo en una variable los datos a guardar id = this.editedItem.idcurso;

      var datos = {
        estatus: this.editedItem.estatus,
        idnotificacion: this.notificacion.idnotificacion,
        idusuario: this.usuario.id_usuario,
        usuario_registro: 1,
        deleted: 0,
      };

      if (this.editedIndex > -1) {
        this.$http
          .put(
            "notificaciones_usuario.update/" +
              this.editedItem.idnotificacion_usuario,
            datos
          )
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));
        Object.assign(
          this.notificaciones_usuario[this.editedIndex],
          this.editedItem
        );
      } else {
        this.$http
          .post("notificaciones_usuario.add", datos)
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));

        this.notificaciones_usuario.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
