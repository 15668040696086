<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">% de Avance</span>
            <v-spacer></v-spacer>
            <v-btn color="green" dark class="mr-2" @click="exportExcel( )" small rounded>
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn color="primary" @click="initialize( )" small rounded>
              Actualizar
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
                <v-autocomplete
                  v-if="calificaciones"
                  :items="calificaciones.empleados"
                  v-model="empleado"
                  label="Empleado"
                  item-text="nombre_completo"
                  item-value="id_usuario"
                  filled
                  hide-details
                  dense
                  single-line
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-autocomplete
                  v-if="calificaciones"
                  :items="calificaciones.cursos"
                  v-model="curso"
                  label="Curso"
                  item-text="curso"
                  item-value="idcurso"
                  filled
                  hide-details
                  dense
                  single-line
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-autocomplete
                  v-if="calificaciones"
                  :items="calificaciones.puestos"
                  v-model="puesto"
                  label="Puesto"
                  item-text="puesto"
                  item-value="idpuesto"
                  filled
                  hide-details
                  dense
                  single-line
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-radio-group
                  v-model="estatus"
                  row
                >
                  <v-radio
                    label="Activo"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Inactivo"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    label="Todos"
                    :value="3"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="calificaciones">
              <v-col cols="12" ms="4" md="3" lg="2" class="text-center mt-0 pt-0" >
                <v-card class="white--text" color="#03005E">
                  <b>Empleados Activos</b><br/>
                  {{ activos.length }}
                </v-card>
              </v-col>

              <v-col cols="12" ms="4" md="3" lg="2" class="text-center mt-0 pt-0" >
                <v-card class="white--text" color="#03005E">
                  <b>Empleados Inactivos</b><br/>
                  {{ inactivos.length }}
                </v-card>
              </v-col>

              <v-col cols="12" ms="4" md="3" lg="2" class="text-center mt-0 pt-0" >
                <v-card class="white--text" color="#03005E">
                  <b>Completos</b><br/>
                  {{ completos }}
                </v-card>
              </v-col>

              <v-col cols="12" ms="4" md="3" lg="2" class="text-center mt-0 pt-0" >
                <v-card class="white--text" color="#03005E">
                  <b>Incompletos</b><br/>
                  {{ incompletos }}
                </v-card>
              </v-col>
            </v-row>

            <v-data-table
              v-if="calificaciones"
              :headers="headers"
              :items="filterCalificaciones"
              :search="search"
              dense
              multi-sort
              class="elevation-0 mt-4"
            >

              <template v-slot:item.nombre_completo="{ item }">
                <v-icon v-if="item.activo_sn" color="success">mdi-circle-medium</v-icon>
                <v-icon v-else                color="red">mdi-circle-medium</v-icon>
                {{ item.nombre_completo }}
              </template>

              <template v-slot:item.avance="{ item }">
                <v-icon v-if="item.avance >= 100" color="success" small left>mdi-check-bold</v-icon>
                <v-icon v-else color="red" small left>mdi-close-thick</v-icon>

                <span v-if="item.avance >= 100">100 </span>
                <span v-else>{{ item.avance }} </span>
                
              </template>

              <template v-slot:item.ver="{ item }">
                <v-chip @click="verCursos( item.cursos )" color="success" dark small>ver</v-chip>
              </template>

             
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                  Actualizar
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
    <CursosEmpleado       v-if="ver"                         :parametros="parametros"/>

  </v-container>
</template>

<script>
 // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import CursosEmpleado        from '@/components/CursosEmpleado.vue';

  import validarErrores   from '@/mixins/validarErrores'


  export default {

    mixins:[ validarErrores ],

    components:{
      Alerta,
      carga,
      CursosEmpleado
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      ///usuarios
      usuario: null,
      usuarios: [],
      evaluacion: null,
      evaluaciones: [],
      dialog: false,
      dialogDelete: false,
      search: "",
      headers: [
        { text: "ID"           , value: "id_usuario"        },
        { text: "Empleado"     , value: "nombre_completo"   },
        { text: "Puesto"       , value: "puesto"            },
        { text: "#Cursos"      , value: "cant_cursos"       },
        { text: "#Realizados"  , value: "curso_realizados"  },
        { text: "%Avance"      , value: "avance"            },
        { text: "Ver"          , value: "ver"               },
      ],
      calificaciones: null, //arreglo
      editedIndex: -1,
      empleado:null,
      curso: null,
      puesto:null,
      estatus:3,

      ver: false,
      parametros:{
        cursos: [],
        estatus: false
      },

    }),

    computed: {
      formTitle() {
        return this.editedIndex === -1 ? "Nuevo curso" : "Editar curso";
      },

      filterCalificaciones( ){
        if( !this.calificaciones ){ return [] }

        if( !this.empleado && !this.curso && !this.puesto){
          if( this.estatus == 3){
            return this.calificaciones.calificaciones
          }else{
            return this.calificaciones.calificaciones.filter( el => { return el.activo_sn == this.estatus })
          }
        }

        if( this.empleado && !this.curso && !this.puesto ){
          if( this.estatus == 3){
            return this.calificaciones.calificaciones.filter( el=> { return el.id_usuario == this.empleado })
          }else{
            return this.calificaciones.calificaciones.filter( el => { return el.id_usuario == this.empleado && el.activo_sn == this.estatus })
          }
        }

        if( !this.empleado && this.curso && !this.puesto ){
          if( this.estatus == 3){
            return this.calificaciones.calificaciones.filter( el=> { return el.idcurso == this.curso })
          }else{
            return this.calificaciones.calificaciones.filter( el => { return el.idcurso == this.curso && el.activo_sn == this.estatus })
          }
        }

        if( !this.empleado && !this.curso && this.puesto){
          if( this.estatus == 3){
            return this.calificaciones.calificaciones.filter( el=> { return el.idpuesto == this.puesto })
          }else{
            return this.calificaciones.calificaciones.filter( el => { return el.idpuesto == this.puesto && el.activo_sn == this.estatus })
          }
        }
      },

      activos ( ){
        return this.filterCalificaciones.filter( el=> { return el.activo_sn })
      },

      inactivos ( ){
        return this.filterCalificaciones.filter( el=> { return !el.activo_sn })
      },

      completos ( ){
        return this.filterCalificaciones.filter( el=> { return el.avance >= 100 }).length
      },

      incompletos ( ){
        return this.filterCalificaciones.filter( el=> { return el.avance < 100 }).length
      }
    },

    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },

    },

    async created() {
      this.initialize();
      // Generando la fecha de hoy
    },

    methods: {
      initialize() {
        this.calificaciones = null;
        this.cargar = true

        //llamo a la api para obtener todos los registros
        this.$http.get("calificaciones.all.empleados").then((response) => {
          // al arreglo que declare previamente le asigno response.body que son todos los registros
          this.cargar = false
          this.calificaciones = response.body;
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      editItem(item) {
        this.editedIndex = this.calificaciones.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },


      exportExcel( ){
        let data = XLSX.utils.json_to_sheet(this.filterCalificaciones)
        const workbook = XLSX.utils.book_new()
        const filename = 'calificaciones_empleados'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'csv',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.csv')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = "https://academicokpi.club/" + 'pdfs/' + filename + '.csv'
        }).catch(error=> {
          console.log(error);
        });
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      verCursos( value ){
        this.parametros.cursos  = value
        this.parametros.estatus = true
        this.ver = true
      }
    },
  };
</script>
