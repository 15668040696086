<template>
	<v-container class="mt-6 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-row justify="space-between">
            <!-- Aquí van los usuarios -->
            <v-col cols="4" class="pt-0 pr-0">
              <v-toolbar
                color="#f0f2f5"
              >
                <v-toolbar-title>Usuarios</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  name="name"
                  label="Buscar"
                  v-model="buscarUsuario"
                  hide-details
                  v-if="buscarUsuarioActive"
                  dense
                  :autofocus="buscarUsuarioActive"
                ></v-text-field>
                <!-- Activar el buscador -->
                <v-btn 
                  icon 
                  @click="buscarUsuarioActive = true" 
                  v-if="!buscarUsuarioActive"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <!-- DEscativar el buscador -->
                <v-btn 
                  icon 
                  @click="buscarUsuarioActive = false" 
                  v-else
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-list nav>

                <template v-for="(usuario, id) in filterUsuarios">

                  <v-list-item
                    :key="usuario.id_usuario"
                    @click="abrirMensajes( usuario )"
                  >
                    <!-- nombre del usuario -->
                    <v-list-item-avatar>
                      <v-img :src="avatar( usuario.empleado)"></v-img>
                    </v-list-item-avatar>

                    <!-- datos del empleado -->
                    <v-list-item-content>
                      <!-- Nombre del empleado -->
                      <v-list-item-title>
                        {{ usuario.empleado }}
                        <v-spacer></v-spacer>
                      </v-list-item-title>

                      <!-- Mensajes -->
                      <v-list-item-subtitle>
                        <!-- Solo debe aparecer si yo soy el emisor  -->
                        <v-icon 
                          left 
                          small 
                          :color="usuario.mensajes[ usuario.mensajes.length - 1 ].visto == 0 ? 'grey' : 'blue' "
                          v-if="usuario.mensajes[ usuario.mensajes.length - 1 ].emisor == getUsuarioCapa.iderp"
                        >
                          mdi-check-all
                        </v-icon>
                        {{ usuario.mensajes[ usuario.mensajes.length - 1 ].mensaje }}
                      </v-list-item-subtitle>

                      <!-- Curso -->
                      <v-list-item-subtitle>
                        <span class="primary--text"><b>{{ usuario.curso }}</b></span>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <!-- los mensajes que aún no se ven -->
                    <v-btn 
                      color="success"
                      class="btn-vistos elevation-0"
                      absolute
                      left
                      top
                      x-small
                      fab
                      v-if="usuario.sinver > 0"
                    >
                      {{ usuario.sinver }}
                    </v-btn>

                  </v-list-item>
                  <v-divider v-if="usuario < (usuarios.length - 1)"></v-divider>
                </template>
              </v-list>
            </v-col>


            <v-divider vertical></v-divider>

            <!-- Preguntas del usuario -->
            <v-col cols="8" class="pa-0">
              <!-- Toolbar de las preguntas -->
              <v-toolbar
                color="#f0f2f5"
              >
                <!-- Avatar del usuario -->
                <v-toolbar-title v-if="usuario">
                  <!-- Avatar del usuario -->
                  <v-avatar size="30" color="white" class="mr-2">
                    <v-img :src="avatar( usuario.empleado)"></v-img>
                  </v-avatar>
                </v-toolbar-title>

                <!-- Nombre del usuario -->
                <div v-if="usuario">
                  {{ usuario.empleado }}
                  <br/>
                  {{ usuario.curso }}
                </div>

                <v-spacer></v-spacer>
                <!-- Buscador -->
                <v-text-field
                  name="name"
                  label="Buscar"
                  v-model="buscarPregunta"
                  hide-details
                  v-if="buscarActive"
                  dense
                  :autofocus="buscarActive"
                ></v-text-field>
                <!-- Activar el buscador -->
                <v-btn 
                  icon 
                  @click="buscarActive = true" 
                  v-if="!buscarActive"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <!-- Descativar el buscador -->
                <v-btn 
                  icon 
                  @click="buscarActive = false" 
                  v-else
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <!-- Mensajesssss -->
              <v-scroll-y-transition mode="out-in">
                <div :style="`height: ${tamanioPantalla + 120 }px; overflow: auto; width:98%; background: url(${require('@/assets/fondo_whatsapp.jpg')}); background-size: cover;`" id="chat" >
                  <!-- Ver las preguntas que realizó el usuario -->
                  <v-card-text class="px-0" >
                    <!-- Div con tamaño de la pantalla -->
                    <div :style="`max-height: ${tamanioPantalla}px; height: ${tamanioPantalla}px; overflow: auto;`" id="preguntas">
                      <!-- Recorremos las preguntas filtradas -->
                      <span v-for="(mensaje, i) in filterMensajes">

                        <!-- Pregunta del usuario -->
                        <v-row v-if="mensaje.emisor != getUsuarioCapa.iderp">
                          <v-col cols="12">
                            <v-card class="ml-6 px-3 pt-3 mt-2" max-width="600" color="#F1F1F1" style="border-radius: 15px; border-top-left-radius: 0; color: '##111b21';">
                              <!-- Pregunta del usuario -->
                              <div class="text-subtitle-1" style="white-space: pre-line"> 
                                {{ mensaje.mensaje }} 
                                <br/>
                                <span v-html="mensaje.fecha_creacion" class="text-caption"></span>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>

                        <!-- Pregunta del usuario -->
                        <v-row v-else>
                          <v-col cols="12" align="right">
                            <v-card class="mr-6 px-3 pt-3 mt-2" max-width="600" color="#d9fdd3"  style="border-radius: 15px; border-top-right-radius: 0px; color: '##111b21'">
                              <!-- Pregunta del usuario -->
                              <div class="text-subtitle-1" style="white-space: pre-line">
                                {{ mensaje.mensaje }} 
                                <br/>
                                <!-- Solo debe aparecer si yo soy el emisor  -->
                                <v-icon 
                                  left 
                                  small 
                                  :color="mensaje.visto == 0 ? 'grey' : 'blue' "
                                >
                                  mdi-check-all
                                </v-icon>
                                <span v-html="mensaje.fecha_creacion" class="text-caption"></span>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>

                      </span>
                    </div>
                  </v-card-text>

                  <v-card-text class="pt-6 white" v-if="usuario">
                    <v-textarea
                      v-model="respuesta"
                      :append-outer-icon="respuesta ? 'mdi-send' : ''"
                      outlined
                      clearable
                      label="Mensaje"
                      type="text"
                      @click:append-outer="enviarMensaje()"
                      hide-details
                      dense
                      auto-grow
                      :rows="1"
                    ></v-textarea>
                  </v-card-text>
                </div>

              </v-scroll-y-transition>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';


  export default {
  	components:{
      Alerta,
      carga,
    },

    data: () => ({
      buscarPregunta:'',
      buscarActive:false,

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      mensajes:[],
      usuarios:[],
      respuesta:'',
      puestos:[],

      usuario: null,
      buscarUsuario:'',
      buscarUsuarioActive:false,
      pregunta: null,
      frecuenteActive:false,
      dialogPreguntaFrecuente:false,
      preguntaOficial:'',
      respuestaOficial:''

    }),

    computed: {
    	...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),

      filterUsuarios(){
        if( this.buscarUsuario != '' ){
          return this.usuarios.filter((p)=>{
            var nom = p.nombre_completo.toLowerCase();
            return nom.match(this.buscarUsuario.toLowerCase());
          })
        }else{ return this.usuarios }
      },

      filterMensajes(){
        if( this.buscarPregunta != '' ){
          return this.mensajes.filter((p)=>{
            var nom = p.pregunta.toLowerCase();
            return nom.match(this.buscarPregunta.toLowerCase());
          })
        }else{ return this.mensajes }
      },

      tamanioPantalla () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return (this.$vuetify.breakpoint.height / 2)
          break;
          case 'sm': 
            return this.$vuetify.breakpoint.height - 300
          break;
          case 'md':
            return this.$vuetify.breakpoint.height - 300
          break;
          case 'lg':
            return this.$vuetify.breakpoint.height - 300
          break;
          case 'xl':
            return this.$vuetify.breakpoint.height - 300
          break;
        }
      },
    },

    async created () {
      await this.initialize()
    },

    methods: {
      initialize () {
        // Buscar info como capacitador
      	this.cargar = true
        this.usuarios = []
        this.mensajes = []
        return this.$http.get('capacitador.mensajes/' + this.getUsuarioCapa.iderp ).then(response=>{
          this.usuarios = response.body
        	this.cargar      = false
          console.log( this.usuarios )
          if( this.usuario ){
            const user = response.body.find( el => el.idusuarioerp == this.usuario.idusuarioerp && el.idcurso == this.usuario.idcurso)
            if( user ){
              this.mensajes  = user.mensajes
            }
          }

        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      abrirMensajes ( usuario ) {
        usuario['usuario'] = this.getUsuarioCapa.iderp
         // actualizar las preguntas a visto 
        this.$http.post('capacitador.mensajes.update', usuario ).then(response=>{
          // Traer los puestos
          usuario.sinver = 0
          this.respuesta = ''
          this.usuario   = usuario
          this.mensajes  = usuario.mensajes

        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      enviarMensaje () {
        const payload = {
          idusuarioerp : this.usuario.idusuarioerp,
          idcapacitador: this.getUsuarioCapa.iderp,
          mensaje      : this.respuesta,
          emisor       : this.getUsuarioCapa.iderp,
          idcurso      : this.usuario.idcurso
        }

        this.$http.post("capacitador.mensaje.add", payload ).then((response) => {
          this.respuesta = ''
          this.cargar    = false
          this.initialize( )
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      avatar ( nombre ) {
        return "https://ui-avatars.com/api/?background=1976d2&color=fff&rounded=true&format=svg&name=" + nombre
      },

      avatarSeleccionado ( nombre ) {
        return "https://ui-avatars.com/api/?background=ffffff&color=e91e63&rounded=true&format=svg&name=" + nombre
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},
    },
  }
</script>

<style  scoped>
  #preguntas::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #preguntas::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar-button:increment,#preguntas::-webkit-scrollbar-button{
    display: none;
  }

  #preguntas::-webkit-scrollbar:horizontal{
    display: none;
  }

  #preguntas::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }


  #chat::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #chat::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #chat::-webkit-scrollbar-button:increment,#chat::-webkit-scrollbar-button{
    display: none;
  }

  #chat::-webkit-scrollbar:horizontal{
    display: none;
  }

  #chat::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  .btn-vistos{
    width: 20px;
    height: 20px;
    margin-top: 30px;
    margin-left: 20px;
  }

  #chat{
    background-image:  'fondo_whatsapp.jpg'
  }

</style>
