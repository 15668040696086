<template>
  <div>
    <v-card flat>
      <v-row v-for="(pregunta_multiples, i) in pregunta_multiple" :key="i">
        <v-col cols="12" sm="8" md="11">
          <v-text-field
            :id="`pregunta_multiples.a${i}`"
            :name="`pregunta_multiples.a${i}`"
            v-model="pregunta_multiples.a"
            :append-icon="'mdi-pencil'"
            label="Opción A:"
            item-value="pregunta_multiples.a "
            item-text="pregunta_multiples.a"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="8" md="12">
          <v-text-field
            :id="`pregunta_multiples.b${i}`"
            :name="`pregunta_multiples.b${i}`"
            v-model="pregunta_multiples.b"
            :append-icon="'mdi-pencil'"
            label="Opción B:"
            item-value="pregunta_multiples.b "
            item-text="pregunta_multiples.b"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="8" md="12">
          <v-text-field
            :id="`pregunta_multiples.c${i}`"
            :name="`pregunta_multiples.c${i}`"
            v-model="pregunta_multiples.c"
            :append-icon="'mdi-pencil'"
            label="Opción C:"
            item-value="pregunta_multiples.c "
            item-text="pregunta_multiples.c"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="8" md="12">
          <v-text-field
            :id="`pregunta_multiples.d${i}`"
            :name="`pregunta_multiples.d${i}`"
            v-model="pregunta_multiples.d"
            :append-icon="'mdi-pencil'"
            label="Opción D:"
            item-value="pregunta_multiples.d "
            item-text="pregunta_multiples.d"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="8" md="12">
          <v-select
            :id="`pregunta_multiples.opcion_correcta${i}`"
            :name="`pregunta_multiples.opcion_correcta${i}`"
            v-model="pregunta_multiples.opcion_correcta"
            :items="opciones"
            label="Opción correcta"
            item-text=".opcion_correcta${i}"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

var moment = require("moment");
moment.locale();

export default {
  data: () => ({
    pregunta: null,
    preguntas: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Opción a", value: "a" },
      { text: "Opción b", value: "b" },
      { text: "Opción c", value: "c" },
      { text: "Opción d", value: "d" },
      { text: "Opción correcta", value: "opcion_correcta" },
      { text: "Pregunta", value: "idpregunta" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    opciones: ["A", "B", "C", "D"],
    pregunta_multiple: [], //arreglo
    editedIndex: -1,
    editedItem: {
      //agrego cada campo a utlizar
      idpregunta_multiple: 0,
      a: "",
      b: "",
      c: "",
      d: "",
      opcion_correcta: "",
      idpregunta: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    defaultItem: {
      idpregunta_multiple: 0,
      a: "",
      b: "",
      c: "",
      d: "",
      opcion_correcta: "",
      idpregunta: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nueva pregunta multiple"
        : "Editar pregunta multiple";
    },
    ...mapGetters("Preguntas", ["getIdPregunta"]),
    ...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    getIdPregunta() {
      this.initialize();
    },
  },

  created() {
    this.initialize();
    // Generando la fecha de hoy
    const fecha = new Date().toLocaleDateString("en-Es", {
      timeZone: "America/Mexico_City",
    });
    this.fecha = moment(fecha)
      .format()
      .substr(0, 10);
  },

  methods: {
    initialize() {
      this.preguntas = [];
      this.$http
        .get("preguntas.all")
        .then((responseTipo) => {
          this.preguntas = responseTipo.body;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$http
        .get("preguntas_opcion_multiple.all/" + this.getIdPregunta)
        .then((responsepregunta_multiple) => {
          this.pregunta_multiple = responsepregunta_multiple.body;
        })
        .catch((error) => {
          console.log(error);
        });

      // preguntas_opcion_multiple.all/
    },

    ...mapActions("Preguntas", ["guardarIdPregunta"]),

    editItem(item) {
      this.editedIndex = this.pregunta_multiple.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.pregunta_multiple.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.pregunta_multiple.splice(this.editedIndex, 1);
      //"Borrando"
      var datos = {
        a: this.editedItem.a,
        b: this.editedItem.b,
        c: this.editedItem.c,
        d: this.editedItem.d,
        opcion_correcta: this.editedItem.opcion_correcta,
        idpregunta: this.editedItem.idpregunta,
        usuario_registro: this.editedItem.usuario_registro,
        deleted: 1,
      };
      this.$http
        .put(
          "pregunta_multiple.update/" + this.editedItem.idpregunta_multiple,
          datos
        )
        .then((response) => {
          this.initialize();
        })
        .catch((error) => console.log(error));

      console.log(this.editedItem.idpregunta_multiple + " borrar");
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      //guardo en una variable los datos a guardar id = this.editedItem.idcurso;
      //recibe los campos del form
      // console.log(this.datos);

      console.log("entrando al save");
      console.log(this.pregunta_multiple[0].a);
      console.log(this.pregunta_multiple[0].idpregunta);

      var datos = {
        a: this.pregunta_multiple[0].a,
        b: this.pregunta_multiple[0].b,
        c: this.pregunta_multiple[0].c,
        d: this.pregunta_multiple[0].d,
        opcion_correcta: this.pregunta_multiple[0].opcion_correcta,
        idpregunta: this.pregunta_multiple[0].idpregunta,
        usuario_registro:
          this.editedItem.usuario_registro === 0
            ? this.getUsuarioCapa.idusuario
            : this.editedItem.usuario_registro,
        deleted: 0,
      };
      console.log(datos);

      this.$http
        .put(
          "pregunta_multiple.update/" +
            this.pregunta_multiple[0].idpregunta_multiple,
          datos
        )
        .then((response) => {
          this.initialize();
        })
        .catch((error) => console.log(error));

      // Object.assign(
      //   this.pregunta_multiple[this.editedIndex],
      //   this.pregunta_multiple
      // );

      // if (this.editedIndex > -1) {

      // } else {
      //   this.$http
      //     .post("pregunta_multiple.add", datos)
      //     .then((response) => {
      //       this.initialize();
      //     })
      //     .catch((error) => console.log(error));

      //   this.pregunta_multiple.push(this.editedItem);
      // }
      // this.close();
    },
  },
};
</script>
