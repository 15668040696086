<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Cursos</span>     
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" small @click="dialog = true">
              Nuevo curso
            </v-btn>
          </v-card-title>


          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  hide-details
                  filled
                  dense
                 ></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              dense
              :headers="headers"
              :items="cursos"
              :search="search"
              :sort-desc="[false, true]"
              multi-sort
              class="elevation-0"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="openViewItem(item)"
                    >
                      <v-icon small>
                        mdi-plus-thick
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar tema</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="openPreviewItem(item)"
                    >
                      <v-icon small>
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Vista previa</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="editItem(item)"
                    >
                      <v-icon small>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar curso</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="deleteItem(item)"
                    >
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar curso</span>
                </v-tooltip>
              </template>

              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                  Actualizar
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-xs-center">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col class="mx-auto" cols="12" lg="6">
                  <v-img
                    :src="foto"
                    contain
                    width="100%"
                    max-height="350"
                    v-if="
                      editedItem.imagen == '' || editedItem.imagen == null
                    "
                  />
                  <v-img
                    :src="url + editedItem.imagen"
                    contain
                    width="100%"
                    max-height="350"
                    v-else
                  />
                </v-col>
                <v-col cols="12" sm="8" md="12">
                  
                  <v-text-field
                    :rules="rules"
                    v-model="editedItem.curso"
                    label="Nombre del curso"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="4">
                  <v-text-field
                    :rules="rules"
                    v-model="editedItem.duracion"
                    label="Duración(hrs)"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <v-file-input
                    @change="cargar()"
                    v-model="fileimagen"
                    accept="image/* "
                    label="Portada"
                    prepend-inner-icon="mdi-image"
                    hide-details
                    dense
                    outlined
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="8" md="12">
                  <v-textarea
                    :rules="rules"
                    v-model="editedItem.descripcion"
                    label="Descripción"
                    value=""
                    rows="2"
                    hide-details
                    outlined
                  ></v-textarea>
                </v-col>
                <!-- Capacitador add item-text="nombre_completo"
                    item-value="id_usuario"-->
                <v-col cols="12" sm="8" md="12">
                  <v-autocomplete
                    v-model="usuario"
                    :items="usuarios"
                    label="Selecciona al Capacitador"
                    persistent-hint
                    prepend-inner-icon="mdi-account"
                    hide-details
                    return-object
                    clearable
                    item-text="empleado"
                    item-value="idusuario"
                    hide-details
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="reset">
            Limpiar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">
          ¿Estás seguro de eliminar este elemento?</v-card-title
        >
        <v-card-actions>
          <v-btn 
            color="error" 
            dark 
            @click="closeDelete"
            class="text-capitalize"
          >
            No, Cancelar
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn 
            color="primary" 
            dark 
            @click="deleteItemConfirm"
            class="text-capitalize"
          >
            Sí, eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogPreview"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar class="elevation-0" dark color="primary">
          <v-btn icon dark @click="closePreview()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Vista previa</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <Preview />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
var moment = require("moment");
moment.locale();

import { mapActions, mapGetters } from "vuex";
import Preview from "./PreviewCursos.vue";
import { Howl, Howler } from "howler";
export default {
  components: {
    Preview,
  },
  data: () => ({
    rules: [(value) => !!value || "Campo obligatorio."],
    //variables utilizadas para subir imgs
    fileimagen: [],
    formdata: "",
    foto:
      "https://carlofarucci.com/wp-content/uploads/2018/09/seo-para-imagenes-2019.jpg",
    url: "",
    ///usuarios
    usuario: null,
    usuarios: [],
    dialog: false,
    dialogDelete: false,
    dialogPreview: false,
    search:"",
    headers: [
      

      {
        text: "Curso",
        value: "curso",
        sortable: true,
        width: "20%",
        align: "start"
      },
      {
        text: "Duración(hrs)",
        value: "duracion",
        align: "start",
        width: "15%",
        sortable: true,
      },
      {
        text: "Descripción",
        value: "descripcion",
         width: '25%',
        sortable: true,
        align: "start"  
      },

      { 
        text: "Capacitador",
        value: "capacitador",
        align: "start",
        width: '20%',
        sortable: true
      },
      { 
        text: "Actions",
        value: "actions",
        align: "start",
        width: '20%', 
        sortable: false 
      },
    ],
    cursos: [], //arreglo
    editedIndex: -1,
    editedItem: {
      //agrego cada campo a utlizar
      idcurso: 0,
      curso: "",
      iderp: 0,
      idcapacitador: 0,
      capacitador: "",
      duracion: 0,
      descripcion: "",
      imagen: "",
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    defaultItem: {
      //agrego cada campo a utlizar
      idcurso: 0,
      curso: "",
      idcapacitador: 0,
      iderp:0 ,
      capacitador: "",
      duracion: 0,
      descripcion: "",
      imagen: "",
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Agregar un curso" : "Editar curso";
    },
    ...mapGetters("Cursos", ["getIdCurso"], ["getCurso"]), //cambios

    ...mapGetters("Materiales", ["getMaterial"]),
    ...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogPreview(val) {
      val || this.closePreview();
    },
    fileimagen() {
      this.editedItem.imagen;
    },
    foto() {
      this.foto;
    },
  },

  created() {
    this.initialize();
    // Generando la fecha de hoy
    const fecha = new Date().toLocaleDateString("en-Es", {
      timeZone: "America/Mexico_City",
    });
    this.fecha = moment(fecha)
      .format()
      .substr(0, 10);

    //
    this.url = "https://academicokpi.club/" + "imagen/";
  },

  methods: {
    initialize() {
      //usuarios.all.erp

      this.$http
        .get("usuarios.all")
        .then((responseUsuarios) => {
          for (const i in responseUsuarios.body) {
            responseUsuarios.body[i].empleado = responseUsuarios.body[
              i
            ].empleado.toUpperCase();
          }
          this.usuarios = responseUsuarios.body;
          
        })
        .catch((error) => {
          console.log(error);
        });

      //llamo a la api para obtener todos los registros
      this.$http
        .get("cursos.all")
        .then((response) => {
          // al arreglo que declare previamente le asigno response.body que son todos los registros
          for (const j in response.body) {
            response.body[j].fecha_creacion = response.body[
              j
            ].fecha_creacion.substr(0, 10);
            response.body[j].fecha_actualizo = response.body[
              j
            ].fecha_actualizo.substr(0, 10);
          }
          console.log(response.body)
          this.cursos = response.body;
        })
        .catch((error) => console.log(error));
    },

    ...mapActions("Cursos", ["guardarIdCurso", "guardarCurso"]), ///cambios

    ...mapActions("Materiales", ["guardarMaterial"]),

    editItem(item) {
      this.usuario = item.idcapacitador;
      this.editedIndex = this.cursos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    openViewItem(item) {
      this.guardarIdCurso(item.idcurso);
      this.$router.push({ name: "Temas" });
    },

    openPreviewItem(item) {
      this.guardarCurso(item); //cambios
      this.dialogPreview = true;

      // this.$router.push({ name: "Preview" });
    },
    deleteItem(item) {
      this.editedIndex = this.cursos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.cursos.splice(this.editedIndex, 1);

      var datos = {
        curso: this.editedItem.curso,
        idcapacitador: this.editedItem.idcapacitador,
        duracion: this.editedItem.duracion,
        descripcion: this.editedItem.descripcion,
        imagen: this.editedItem.imagen,
        usuario_registro: this.editedItem.usuario_registro,
        fecha_actualizo: this.fecha,
        deleted: 1,
      };

      this.$http
        .put("cursos.update/" + this.editedItem.idcurso, datos)
        .then((response) => {
          this.initialize();
        })
        .catch((error) => console.log(error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closePreview() {
      this.dialogPreview = false;
      Howler.stop();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      //recibe los campos del form

      //guardo en una variable los datos a guardar id = this.editedItem.idcurso;

      var datos = {
        curso: this.editedItem.curso,
        idcapacitador:
          this.editedItem.idcapacitador === 0
            ? this.usuario.idusuario
            : this.editedItem.idcapacitador,
        duracion: this.editedItem.duracion,
        descripcion: this.editedItem.descripcion,
        imagen: this.editedItem.imagen,
        usuario_registro:
          this.editedItem.usuario_registro === 0
            ? this.getUsuarioCapa.idusuario
            : this.editedItem.usuario_registro,
        fecha_creacion:
          this.editedItem.fecha_creacion === null
            ? this.fecha
            : this.editedItem.fecha_creacion,
        fecha_actualizo: this.fecha,
        deleted: 0,
      };

      console.log(this.fileimagen);

      if (this.fileimagen.name != undefined) {
        datos.imagen = this.fileimagen.name;
      }

      if (this.editedIndex > -1) {
        this.$http
          .put("cursos.update/" + this.editedItem.idcurso, datos)
          .then((response) => {
            if (this.foto != "") {
              //esta api es para subir el archivo al server
              this.$http
                .post("imagenes", this.formdata)
                .then((response) => {
                  this.reset();
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            this.initialize();
          })
          .catch((error) => console.log(error));
        Object.assign(this.cursos[this.editedIndex], this.editedItem);
      } else {
        this.$http
          .post("cursos.add", datos)
          .then((response) => {
            if (this.formdata == "") {
              this.reset();
            } else {
              //esta api es para subir el archivo al server
              this.$http
                .post("imagenes", this.formdata)
                .then((response) => {
                  this.reset();
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            this.initialize();
          })
          .catch((error) => console.log(error));

        this.cursos.push(this.editedItem);
      }
      this.close();
    },

    reset() {
      this.$refs.form.reset();
      // this.fileimagen = [];
      this.fileimagen = [];
      this.editedItem = {};
    },

    cargar() {
      if (this.fileimagen.name != undefined) {
        // creamos una variable tipo FormData
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", this.fileimagen);
        //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
        this.getBase64(this.fileimagen, formData);
      }
    },

    getBase64(file, formData) {
      var me = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        me.foto = reader.result; //base64
        me.formdata = formData; //archivo
      };
    },
  },
};
</script>
