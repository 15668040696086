<template>
  <v-container fluid >
    <v-card class="elevation-0 transparent" :rounded="false">
      <v-row justify="space-around">

        <!-- Vista previa del material a ver -->
        <v-col cols="12" md="8" lg="8" class="pa-0">

          <!-- Mostrar material si es que hay -->
          <div v-if="materialItem.url != null">
            <!-- Video -->
            <video :height="height"
              :class="{'ml-7 mt-n2': $vuetify.breakpoint.smAndDown, 'ml-14 my-18': $vuetify.breakpoint.smAndUp}"
              allowfullscreen
              :src="url2 + materialItem.url"
              width="90%"
              controls
              scrolling="auto"
              v-if="materialItem.idtipo_material == 1"
            ></video>

            <!-- <AudioPlayer  />-->
            <v-card v-if="materialItem.idtipo_material == 2" :rounded="false" style="border-radius: 0px;">
              <v-progress-linear
                class="mt-2"
                height="5"
                v-model="trackProgress"
                @click="updateSeek($event)"
              ></v-progress-linear>
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    ><h3>
                      {{ materialItem.url.replace(".mp3", "") }}
                    </h3>
                  </v-card-title>
                  <v-container>
                    <h5 dense>
                      {{ getTrackInfo.seek | minutes }}/{{
                        getTrackInfo.duration | minutes
                      }}
                    </h5>

                    <v-card-actions>
                      <v-btn
                        v-if="play == false"
                        outlined
                        fab
                        small
                        color="light-blue"
                        @click="playTrack()"
                      >
                        <v-icon large>mdi-play</v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        outlined
                        fab
                        small
                        color="light-blue"
                        @click="pauseTrack()"
                      >
                        <v-icon large>mdi-pause</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </div>

                <v-avatar class="ma-3" size="125" tile>
                  <v-icon large>mdi-headphones</v-icon>
                </v-avatar>
              </div>
            </v-card>

            <!-- <AudioPlayer  />-->
            <v-img
              v-if="materialItem.idtipo_material == 3"
              contain
              :src="url + materialItem.url"
              class="white--text align-end"
              height="600"
              width="100%"
            >
            </v-img>

            <!-- Material de PDF   />-->
            <embed
              type="application/pdf"
              id="pdf"
              v-if="materialItem.idtipo_material == 4"
              :src="url3 + materialItem.url + '#toolbar=0'"
              height="600"
              width="100%"
            />

            <!-- Presentación Power Point -->
            <iframe 
              v-if="materialItem.idtipo_material == 6"
              :src="`https://view.officeapps.live.com/op/embed.aspx?wdPrint=0&zoom=0&src=${ url3 + materialItem.url }`"
              width="100%" 
              height="400"
              frameborder='0'
              sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts" 
              scrolling="no" 
              style="border: none; max-width: 100%; max-height: 100vh" 
              allowfullscreen 
              mozallowfullscreen 
              msallowfullscreen 
              webkitallowfullscreen
            ></iframe>
          </div>

          <!-- Mostrar portada en caso de no haber material -->
          <v-card  v-else flat :height="hVideo" width="100%" style="border-radius: 0px;">
            <v-img
              contain
              :src="url + getCurso.imagen"
              class="white--text align-end"
              max-width="100%"
            >
            </v-img>
          </v-card>

          <!-- Datos del curso-->
          <v-row align="center">
            <!-- Descripción del curso  -->
            <v-col cols="12" clas="pa-0">
              <v-card-title>
                <h2>{{ getCurso.curso }}</h2>
                <v-spacer></v-spacer>
                <v-btn
                  color="white"
                  fab
                  @click="copiarEnlaceMaterial()"
                >
                  <v-icon color="primary">
                    mdi-clipboard-file
                  </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text> {{ getCurso.descripcion }} </v-card-text>
              <v-row  class="pa-md-0 mx-lg-auto">
                <v-col class="my-0 pt-0">
                  <v-card-text> Duración:</v-card-text>
                  <v-card-text class="my-n8 mx-auto">
                    <v-icon>
                      mdi-clock-time-two-outline
                    </v-icon>
                    <span class=" font-weight-light">{{
                      getCurso.duracion + " horas"
                    }}</span>
                  </v-card-text>
                </v-col>
                <v-col cols="20" sm="12" md="4">
                  <v-card-text> Capacitador:</v-card-text>
                  <v-card-text class="mt-n8 mx-auto">
                    <v-avatar class="mr-2" size="30px">
                      <img
                        :src='`https://ui-avatars.com/api/?background=1976d2&color=fff&rounded=true&format=svg&name=${getCurso.capacitador}`'
                        alt="John"
                      />
                    </v-avatar>
                    <span class=" font-weight-light"
                      >{{ getCurso.capacitador }}
                    </span>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <!-- Si ya hay material, hay que traer los comentarios de su curso -->
              <div class="px-2">
                <div class="text-h6 mb-2">comentarios | {{ comentarios.length }} |</div>

                <v-row>
                  <v-col v-if="comentarios.length > 0" cols="12" class="mb-4">
                    <div 

                      v-for="(com, i) in comentarios"
                      :key="i"
                    >
                      <v-textarea
                        auto-grow
                        v-model="com.nota"
                        filled
                      >  
                      </v-textarea>
                      <!-- <v-card 
                        class="mr-6 mt-2 pa-3" 
                        dark 
                        color="#49ABF3" 
                        shaped 
                        style="border-radius: 15px; border-top-left-radius: 0; white-space: pre-line"
                      >
                        {{ com.nota }}</pre>
                      </v-card> -->
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <!-- Vista previa del material a ver -->
        <!-- Contenido del curso -->
        <v-divider vertical></v-divider>

        <v-col cols="12" md="4" lg="4" class="pa-0">
          <v-card class="elevation-0">
            <v-toolbar class="elevation-0 text-h6">
              Contenido del curso
            </v-toolbar>

            <v-list nav dense class="mr-0">
              <v-list-group
                v-for="item in temas"
                :key="item.idtemas"
                v-model="item.active"
                prepend-icon="mdi mdi-circle-medium theme--light"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content> 
                    <v-list-item-title v-text="item.tema"></v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list dense nav v-if="item.subtemas">
                  <v-list-group
                    v-for="sub in item.subtemas"
                    :key="sub.idsubtema"
                    v-model="sub.active"
                    :prepend-icon="sub.action"
                    no-action
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title v-text="sub.subtema"></v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      v-for="mat in sub.materiales"
                      :key="mat.idmaterial"
                      @click="materialShow(mat)"
                    >

                      <v-list-item-icon dense>
                        <v-icon dense v-if="mat.extension == 'mp4'" color="red">mdi-play-box</v-icon>
                        <v-icon dense v-if="mat.extension == 'mp3'" color="black">mdi-microphone</v-icon>
                        <v-icon dense v-if="mat.extension == 'pdf'" color="red">mdi-file-pdf-box</v-icon>
                        <v-icon dense v-if="['jpg','webp','gif','png','jpeg'].includes(mat.extension)" color="primary">mdi-camera</v-icon>
                        <v-icon dense v-if="['ppt', 'pptx'].includes(mat.extension)" color="red">mdi-microsoft-powerpoint</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title v-text="mat.nombre"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-for="evaluacion in sub.evaluaciones"
                      :key="evaluacion.idevaluacion"
                      link
                      dense
                    >

                      <v-list-item-icon dense>
                        <v-icon dense color="green">mdi-pencil-ruler</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title
                        dense
                        v-text="evaluacion.evaluacion"
                      ></v-list-item-title>
                    </v-list-item>

                  </v-list-group>
                </v-list>
              </v-list-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      top
      right
      color="green"
      :timeout="2000"
    >
      Texco copiado correctamente

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
var moment = require("moment");
moment.locale();

import { mapActions, mapGetters } from "vuex";

import { Clipboard } from '@capacitor/clipboard';

const  base64 = require('base-64');

export default {
  data: () => ({
    url: "",
    url2: "",
    urlpdf: "",
    tema: null,
    temas: [],
    materialItem: {},
  
    //**---audio---**//
    play: false,
    url3: "",
    playing: false,
    seek: 0,
    progress: 0,
    duration: 0,
    snackbar: false,
    comentarios:[],
  }),


  computed: {
    height () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 270
        case 'sm': return 270
        case 'md': return 600
        case 'lg': return 600
        case 'xl': return 600
      }
    },

    hVideo () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 0
        case 'sm': return 0
        case 'md': return 600
        case 'lg': return 600
        case 'xl': return 600
      }
    },

    formTitle() {
      return this.editedIndex === -1 ? "Nuevo curso" : "Editar curso";
    },
    ...mapGetters("Cursos", ["getCurso"]),
    ...mapGetters("Materiales", ["getMaterial"]),

    //**---audio---**//
    trackProgress() {
      this.progress = this.seek / this.audio.duration();
      return this.progress * 100;
    },
    getTrackInfo() {
      let seek = this.seek,
        duration = this.audio.duration();
      return {
        seek,
        duration,
      };
    },
  },

  watch: {
    getCurso() {
      console.log("curso " + this.getCurso.idcurso);
      this.initialize();
    },

    temas() {
      console.log("tema");
    },

    materialItem() {
      //**---audio---**//
      this.url4 = "https://academicokpi.club/" + "capacitacion-audios/" + this.getMaterial.url;
      this.audio = new Howl({
        src: [this.url4],
        onend: () => {
          this.stopTrack();
          this.play = false;
        },
      });
    },

    //**---audio---**//
    playing(playing) {
      this.seek = this.audio.seek();
      let updateSeek;
      if (playing) {
        updateSeek = setInterval(() => {
          this.seek = this.audio.seek();
        }, 250);
      } else {
        clearInterval(updateSeek);
      }
    },

    audio() {},
  },

  created() {
    this.initialize();
    this.url  = "https://academicokpi.club/" + "capacitacion-imagenes/";
    this.url2 = "https://academicokpi.club/" + "capacitacion-videos/";
    this.url3 = "https://academicokpi.club/" + "capacitacion-archivos/";
    this.url4 = "https://academicokpi.club/" + "capacitacion-audios/";
  },

  methods: {
    initialize() {
      this.materialItem = {};
      this.$http.get("temas.cursos.all/" + this.getCurso.idcurso).then((responsetemas) => {
        this.temas = responsetemas.body;
        for( const i in this.temas ){
          this.temas[i]['active'] = false
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },

    ...mapActions("Materiales", ["guardarMaterial", "guardarPDF"]),
    ...mapActions("Evaluaciones", ["guardarIdEvaluacion", "guardarEvaluacion"]),

    async materialShow(material) {
      this.play = false;
      this.stopTrack();
      this.materialItem = material;
      this.guardarMaterial(material);
      await this.getComentarios()
    },

    getComentarios( ){
      const payload = {
        idmaterial: this.materialItem.idmaterial,
      }

      this.comentarios = []
      this.cargar      = true
      this.$http.post("materiales.comentarios.all", payload ).then((response) => {
        this.comentarios   = response.body;
        this.cargar        = false
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    openItem(item) {
      this.guardarEvaluacion(item);
      // this.$router.push({ name: "PreviewEvaluacion" });
    },

    //**---audio---**//
    playTrack() {
      this.audio.play();
      this.play = true;
      this.playing = true;
    },

    pauseTrack() {
      this.play = false;
      this.audio.pause();
      this.playing = false;
    },
    stopTrack() {
      this.audio.stop();
      this.playing = false;
    },
    //linear progress bar
    updateSeek(event) {
      if (this.audio.playing()) {
        this.audio.seek((this.audio.duration() / 100) * percents);
      }

      let el = document.querySelector(".progress-linear__bar"),
        mousePos = event.offsetX,
        elWidth = el.clientWidth,
        percents = (mousePos / elWidth) * 100;
    },

    copiarEnlaceMaterial ( ){
      console.log ( this.materialItem )
      if( this.materialItem.idmaterial ){
        const idMaterial = base64.encode( this.materialItem.idmaterial )

        const ruta = `Curso:    ${this.materialItem.curso}
Subtema:  ${this.materialItem.subtema}
Material: ${this.materialItem.nombre}
https://capacitacion.schoolofenglishadmin.com/#/material/${idMaterial}`
        Clipboard.write({
          string: ruta
        });

        this.snackbar = true
      }
    }

  },
};
</script>
