<template>
  <v-card class="mx-auto" flat>
    <v-container>
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-card-title> 
        <v-btn class="text-capitalize mr-2" color="red" dark @click="goBack()" rounded small>
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn> 
        {{ getEvaluacion.evaluacion }}
      </v-card-title>

      <v-divider class="mx-4"></v-divider>

      <!-- Primero validamos las calificaciones  -->
      <div v-if="ultima && ultima.calificacion < 70">
        <v-alert
          border="left"
          color="red"
          dense
          outlined
          type="error"
        >
          Tu calificación es:
          <strong>{{ ultima.calificacion }}.</strong> Por favor vuelve a realizar la evaluación.
        </v-alert>
      </div>

      <!-- Validamos si paso  -->
      <div v-if="ultima && ultima.calificacion >= 70">
        <v-alert
          border="left"
          color="green"
          dense
          outlined
          type="success"
        >
          ¡Has aprobado esta evaluación! Tu calificación es:
          <strong>{{ ultima.calificacion }}</strong>.
        </v-alert>
      </div>

      <!-- Instrucciones para contestar la evaluación -->
      <v-card-text v-if="!ultima || ( ultima && ultima.calificacion < 70 )">
        <div>
          <strong>Instrucciones:</strong> Lee atentamente cada pregunta y elige la respuesta correcta.
        </div>
      </v-card-text>

      <!--Si el arreglo es nulo entonces mostramos la evaluacion o -->
      <div v-if="!ultima || ( ultima && ultima.calificacion < 70 )">
        <!-- Preguntas -->
        <v-card-title dense>Preguntas</v-card-title>

        <v-card-text>
          <!-- Desglose -->
          <v-radio-group
            v-for="p in preguntas"
            :key="p.idpregunta"
            v-model="p.eleccion"
          >

            <template v-slot:label>
               <p 
                class="text" 
                style="white-space: pre-line"
              >
                <strong> {{ p.numero_pregunta + "." + " " + p.pregunta }}</strong>
              </p>


            </template>


            <v-radio :label="p.a" :value="1"></v-radio>
            <v-radio :label="p.b" :value="2"></v-radio>
            <v-radio :label="p.c" :value="3" v-if="p.c != '' && p.c != '-'"></v-radio>
            <v-radio :label="p.d" :value="4" v-if="p.d != '' && p.d != '-'"></v-radio>

            <v-divider></v-divider>
          </v-radio-group>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="my-2">
            <v-btn class="mx-6 my-8 text-capitalize"color="red" dark @click="goBack()" rounded>
              Regresar
            </v-btn>
            <v-btn class="text-capitalize" color="primary" dark @click="enviarRespuesta(preguntas)" rounded>
              Enviar respuestas
            </v-btn>
          </div>
          <v-spacer></v-spacer>
        </v-card-actions>
      </div>
    </v-container>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>


  </v-card>
</template>

<script>
var moment = require("moment");
moment.locale();

import { mapActions, mapGetters } from "vuex";

// Componentes
import Alerta                from '@/components/alertas/Alerta.vue'
import carga                 from '@/components/alertas/carga.vue';

export default {
  components:{
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros:{
      dialogError: false,
      mensaje: '',
      color: ''
    },

    respuestaAlerta:false,
    loader: true,
    cargar: false,

    pregunta: null,
    preguntas: [],
    n_intentos: 0,
    calificacion: 0,
    calificacion_total: 0,
    editedItem: {
      //agrego cada campo a utlizar
      idevaluacion: 0,
      evaluacion: "",
      idtipo_evaluacion: 0,
      idsubtema: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    defaultItem: {
      //agrego cada campo a utlizar
      idevaluacion: 0,
      evaluacion: "",
      idtipo_evaluacion: 0,
      idsubtema: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    mensaje: "",
    constestado: false, //para saber si ya contesto el examen
    calificaciones: [],
    ultima: null,
  }),

  computed: {
    ...mapGetters("Cursos", ["getIdCurso"], ["getCurso"]),
    ...mapGetters("Evaluaciones", ["getEvaluacion"]),
    ...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),
  },

  watch: {
    getEvaluacion() {
      this.initialize();
    },
  },

  async created() {
    await this.initialize();
    await this.getIntentos()
  },

  methods: {
    initialize() {
      this.cargar = true
      // Obtener las preguntas de la evaluación
      return this.$http.get("preguntas.opcion.all/" + this.getEvaluacion.idevaluacion).then((responsepreguntas) => {
        this.preguntas = responsepreguntas.body;
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    getIntentos( ){
      this.cargar = true
      var calificaciones = {
        idevaluacion: this.getEvaluacion.idevaluacion,
        idempleado: this.getUsuarioCapa.idusuario, //modificar acá
      };

      //comprobamos si ya se encuentra aprobada la evaluacion
      return this.$http.post("calificaciones.intentos.all", calificaciones).then((response) => {
        console.log( response )
        this.constestado    = true 
        this.ultima         = response.body
      }).catch( error =>{
        this.constestado    = false
        this.ultima         = null
        // this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    enviarRespuesta(preguntas) {
      this.cargar = true
      this.constestado = false;
      const payload = {
        idempleado:        this.getUsuarioCapa.idusuario,
        usuario_registro:  this.getUsuarioCapa.idusuario,
        idevaluacion:      this.getEvaluacion.idevaluacion,
        preguntas,
      }

      // guardar las calificaciones
      this.$http.post("calificaciones.add", payload).then((response) => {
        this.cargar = false
        this.validarSuccess( response.body.message )
        this.constestado = true;
        this.initialize();
        this.getIntentos()
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    validarSuccess( mensaje ){
      this.cargar = false
      this.parametros.mensaje      = mensaje
      this.parametros.dialogError  = true 
      this.parametros.color        = 'success'
      this.respuestaAlerta         = true 
    },

    validarError ( value ) {
      this.cargar = false 
      if( value.status == 400 || value.status == 500){
        this.parametros.mensaje      = value.body.message
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
      }
    },

    validarErrorDirecto ( mensaje ) {
      this.cargar = false 
      this.parametros.mensaje      = mensaje
      this.parametros.dialogError  = true 
      this.parametros.color        = '#FF2C2C'
      this.respuestaAlerta = true 
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>
