<template>
  <v-data-table
    :headers="headers"
    :items="tipo_material"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Tipo material</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Nuevo tipo de material
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="8" md="9">
                    <v-text-field
                      v-model="editedItem.tipo_material"
                      label="Tipo material"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >¿Estás seguro de eliminar este elemento?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
var moment = require("moment");
moment.locale();

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "idtipo_material",
      },
      { text: "Tipo material", value: "tipo_material" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    tipo_material: [], //arreglo
    editedIndex: -1,
    editedItem: {
      //agrego cada campo a utlizar
      idtipo_material: 0,
      tipo_material: "",

      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    defaultItem: {
      idtipo_material: 0,
      tipo_material: "",

      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo tipo material"
        : "Editar tipo material";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    // Generando la fecha de hoy
    const fecha = new Date().toLocaleDateString("en-Es", {
      timeZone: "America/Mexico_City",
    });
    this.fecha = moment(fecha)
      .format()
      .substr(0, 10);
  },

  methods: {
    initialize() {
      this.$http
        .get("tipo_materiales.all")
        .then((responsetipo_material) => {
          this.tipo_material = responsetipo_material.body;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.tipo_material.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.tipo_material.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.tipo_material.splice(this.editedIndex, 1);
      //"Borrando"
      var datos = {
        tipo_material: this.editedItem.tipo_material,
        usuario_registro: 1,
        fecha_creacion: this.editedItem.fecha_creacion,
        fecha_actualizo: this.fecha,
        deleted: 1,
      };
      this.$http
        .put("tipo_materiales.update/" + this.editedItem.idtipo_material, datos)
        .then((response) => {
          this.initialize();
        })
        .catch((error) => console.log(error));

      console.log(this.editedItem.idtipo_material + " borrar");
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      //recibe los campos del form
      console.log(this.editedItem);

      //guardo en una variable los datos a guardar id = this.editedItem.idcurso;

      var datos = {
        tipo_material: this.editedItem.tipo_material,
        usuario_registro: 1,
        fecha_creacion:
          this.editedItem.fecha_creacion === null
            ? this.fecha
            : this.editedItem.fecha_creacion,
        fecha_actualizo: this.fecha,
        deleted: 0,
      };

      if (this.editedIndex > -1) {
        this.$http
          .put(
            "tipo_materiales.update/" + this.editedItem.idtipo_material,
            datos
          )
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));
        Object.assign(this.tipo_material[this.editedIndex], this.editedItem);
      } else {
        this.$http
          .post("tipo_materiales.add", datos)
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));

        this.tipo_material.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
