<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title class="text-subtitle-1">
            Inscritos
            <v-spacer></v-spacer>
            <v-btn 
            	color="primary" 
            	dark 
            	class="mb-2" 
            	small 
            	@click="initialize()"
            	tile
            >
            	<v-icon small left>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  filled
                  dense
                  rounded
                ></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              dense
              :headers="headers"
              :items="cursos_empleados"
              :search="search"
              multi-sort
              class="elevation-0"
            >
            </v-data-table>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>

<script>
  /*
    1-. General
    2-. Departamento
    3-. Puesto
    4-. Empleado
  */
  var moment = require("moment");
  moment.locale();
  import { mapActions, mapGetters } from "vuex";

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search: "",
      headers: [
        
        { text: "Nombre" , value: "nombre_completo",sortable: true, },
        { text: "Plantel", value: "plantel"        ,sortable: true, },
        { text: "Email"  , value: "email"          ,sortable: true, },
        { text: "Fecha"  , value: "fecha_creacion" ,sortable: true, },
      ],
      cursos_empleados: [], //arreglo
    }),

    computed: {
      ...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),
    },

    async created() {
      await this.initialize();
    },

    methods: {
      initialize() {
        this.cargar = true
        this.cursos_empleados = []
        //llamo a la api para obtener todos los registros
        return this.$http.get("inscripcion.curso.teachers").then((response) => {
        	console.log( response.body )
          this.cursos_empleados = response.body;
          this.cargar = false
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },
    },
  };
</script>
