import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

export default {
    namespaced: true,
    state: {
        material: {
            // idmaterial: 0,
            url: "",
            idsubtema: 0,
            idtipo_material: 0,
            usuario_registro: 0,
            // fecha_creacion: null,
            // fecha_actualizo: null,
            deleted: 0,
        },
        status: false,
        pdf: "",
    },

    mutations: {
        MATERIAL(state, material) {
            state.material = material
        },
        STATUS(state, status) {
            state.status = status
        },
        PDF(state, pdf) {
            state.pdf = pdf
        }
    },

    actions: {
        guardarMaterial({ commit, dispatch }, item) {
            commit('MATERIAL', item)
            console.log("material:  " + item.url)
        },
        guardarStatus({ commit, dispatch }, stop) {
            commit('STATUS', stop)
            console.log("status:  " + stop)
        },

        guardarPDF({ commit, dispatch }, pdf) {
            commit('PDF', pdf)
            console.log("pdf:  " + pdf)
        }
    },

    getters: {
        getMaterial(state) {
            return state.material
        },
        getStatus(state) {
            return state.status
        },
        getPDF(state) {
            return state.pdf
        },
    }
}