<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title class="text-subtitle-1">
            Asignación de cursos
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" small @click="dialog = true">
              Nueva asignación
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  filled
                  dense
                  rounded
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              dense
              :headers="headers"
              :items="asignaciones"
              :search="search"
              :sort-desc="[false, true]"
              multi-sort
              class="elevation-0"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title >
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form" lazy-validation>
              <v-row>
                <!-- select tipo  -->
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-model="tipo"
                    :items="tipos"
                    item-text="tipo"
                    item-value="valor"
                    label="Selecciona un tipo de asignación"
                    persistent-hint
                    prepend-inner-icon="mdi-shape"
                    hide-details
                    outlined
                    dense
                  >
                  </v-select>
                </v-col>
                <!-- departamento add -->
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-if="tipo == 2"
                    v-model="departamento"
                    :items="departamentos"
                    label="Selecciona un departamento"
                    persistent-hint
                    prepend-inner-icon="mdi-office-building"
                    hide-details
                    item-text="departamento"
                    item-value="iddepartamento"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                  <v-autocomplete
                    v-else-if="tipo == 3"
                    :disabled="!habilitar"
                    v-model="puesto"
                    :items="puestos"
                    label="Selecciona un puesto"
                    persistent-hint
                    prepend-inner-icon="mdi-account-supervisor"
                    hide-details
                    item-text="puesto"
                    item-value="idpuesto"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                  <v-autocomplete
                    v-else-if="tipo == 4"
                    v-model="usuario"
                    :items="usuarios"
                    label="Selecciona un usuario"
                    persistent-hint
                    prepend-inner-icon="mdi-account"
                    hide-details
                    return-object
                    clearable
                    item-text="empleado"
                    item-value="idusuario"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                  <v-select
                    v-else
                    disabled
                    label="Selecciona un tipo de asignación"
                    prepend-inner-icon=""
                    outlined
                    dense
                  >
                  </v-select>
                </v-col>

                <!-- Cursos add -->
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="curso"
                    :items="cursos"
                    clearable
                    label="Selecciona un curso"
                    persistent-hint
                    prepend-inner-icon="mdi-school"
                    hide-details
                    return-object
                    item-text="curso"
                    item-value="idcurso"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    type="date"
                    v-model="editedItem.fecha_inicio"
                    label="Fecha inicio"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    type="date"
                    v-model="editedItem.fecha_final"
                    label="Fecha final"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-alert
              type="warning"
              outlined
              dense
              v-if="existe_asignacion != ''"
            >
              <div>{{ existe_asignacion }}</div>
            </v-alert>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" dark small rounded @click="close">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark small rounded @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >¿Estás seguro de eliminar este elemento?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>

<script>
  /*
    1-. General
    2-. Departamento
    3-. Puesto
    4-. Empleado
  */
  var moment = require("moment");
  moment.locale();
  import { mapActions, mapGetters } from "vuex";

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      search: "",
      headers: [
        
        { text: "Tipo", value: "tipo",sortable: true,
          align: "start",width: '11%' },
        { text: "Curso", value: "curso",sortable: true,
          align: "start" ,width: '11%'},
        { text: "General", value: "gral",sortable: true,
          align: "start",width: '11%' },
        { text: "Departamento", value: "departamento",sortable: true,
          align: "start" ,width: '11%'},
        { text: "Puesto", value: "puesto",sortable: true,
          align: "start",width: '11%' },
        { text: "Empleado", value: "empleado",sortable: true,
          align: "start" ,width: '11%'},
        { text: "Fecha inicio", value: "fecha_inicio",sortable: true,
          align: "start" ,width: '11%'},
        { text: "Fecha final", value: "fecha_final",sortable: true,
          align: "start" ,width: '11%'},
        { text: "Actions", value: "actions", sortable: false,sortable: true,
          align: "start" ,width: '11%'},
      ],
      cursos_empleados: [], //arreglo
      editedIndex: -1,
      editedItem: {
        idasignacion_curso: 0,
        tipo_asignacion: 0,
        idcurso: 0,
        general: 0,
        iddepartamento: 0,
        idpuesto: 0,
        idempleado: 0,
        fecha_inicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        fecha_final: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        usuario_registro: 0,
        fecha_creacion: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        fecha_actualizo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        deleted: 0,
      },
      defaultItem: {
        //agrego cada campo a utlizar
        idasignacion_curso: 0,
        tipo_asignacion: 0,
        idcurso: 0,
        general: 0,
        iddepartamento: 0,
        idpuesto: 0,
        idempleado: 0,
        fecha_inicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        fecha_final: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        usuario_registro: 0,
        fecha_creacion: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        fecha_actualizo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        deleted: 0,
      },
      habilitar: false,
      //tipode asignacion
      tipo: 4,
      tipos: [
        { valor: 1, tipo: "General" },
        { valor: 2, tipo: "Departamento" },
        { valor: 3, tipo: "Puesto" },
        { valor: 4, tipo: "Empleado" },
      ],
      ///usuarios
      usuario: null,
      usuarios: [],
      //cursos
      curso: null,
      cursos: [],
      //depto
      departamentos: [],
      departamento: null,
      //puesto
      puesto: null,
      puestos: [],
      asignaciones: [],
      existe_asignacion: "",
    }),

    computed: {
      formTitle() {
        return this.editedIndex === -1 ? "Nueva asignación" : "Editar asignación";
      },
      ...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),
    },

    watch: {
      dialog(val) {
        val || this.close();
      },

      dialogDelete(val) {
        val || this.closeDelete();
      },
    
      departamento() {
        //genero consulta para buscar por depto
        this.$http.get("usuarios.depto.all/" + this.departamento).then((response) => {
          this.usuarios = response.body;
          console.log(this.usuarios);
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      puesto() {
        //genero consulta para buscar por puesto
        this.$http.get("usuarios.puesto.all/" + this.puesto).then((response) => {
          this.usuarios = response.body;
          console.log(this.usuarios);
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },
 
    },

    async created() {
      await this.getCursosCapa();
      await this.initialize();
      await this.getUsuariosCapa();
      // Generando la fecha de hoy
      const fecha = new Date().toLocaleDateString("en-Es", {
        timeZone: "America/Mexico_City",
      });
      this.fecha = moment().format("YYYY-MM-DD HH:mm:ss");
    },

    methods: {
      getCursosCapa() {
        this.cargar = true
        return this.$http.get("cursos.all").then((responseCursos) => {
          this.cursos = responseCursos.body;
          this.cargar = false
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      initialize() {
        this.cargar = true
        //llamo a la api para obtener todos los registros
        return this.$http.get("asignacion_cursos.all").then((response) => {
          this.asignaciones = response.body;
          this.cargar = false
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      getUsuariosCapa() {
        this.cargar = true
        return this.$http.get("usuarios.all").then((responseUsuarios) => {
          for (const i in responseUsuarios.body) {
            responseUsuarios.body[i].empleado = responseUsuarios.body[i].empleado.toUpperCase();
          }
          this.usuarios = responseUsuarios.body;
          this.cargar = false
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      editItem(item) {
        this.curso = item.idcurso;
        this.tipo = item.tipo_asignacion;
        this.departamento = item.iddepartamento;
        this.puesto = item.idpuesto;
        this.usuario = item.idempleado;

        this.editedIndex = this.asignaciones.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      deleteItem(item) {
        this.editedIndex = this.asignaciones.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },

      deleteItemConfirm() {
        this.asignaciones.splice(this.editedIndex, 1);

        var datos = {
          tipo_asignacion: this.editedItem.tipo_asignacion,
          idcurso: this.editedItem.idcurso,
          general: this.editedItem.general,
          iddepartamento: this.editedItem.iddepartamento,
          idpuesto: this.editedItem.idpuesto,
          idempleado: this.editedItem.idempleado,
          fecha_inicio: this.editedItem.fecha_inicio,
          fecha_final: this.editedItem.fecha_final,
          usuario_registro: this.editedItem.usuario_registro,
          fecha_actualizo: this.fecha,
          deleted: 1,
        };

        this.$http
          .put(
            "asignacion_cursos.update/" + this.editedItem.idasignacion_curso,
            datos
          )
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));
        this.closeDelete();
      },

      close() {
        this.dialog = false;
        this.existe_asignacion = "";
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      reset() {
        this.dialog = false;
        this.curso = null
        this.habilitarUsuario = false;
        this.existe_asignacion = "";
      },

      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      save() {
        if(this.editedItem.fecha_inicio && this.editedItem.fecha_final){
          //creamos un array para guardar los datos de la asignación
          var datos = {
            tipo_asignacion:   this.tipo,
            idcurso:           this.curso.idcurso,
            general:           this.tipo == 1 ? 1 : 0,
            iddepartamento:    this.departamento == null ? 0 : this.departamento,
            idpuesto:          this.tipo == 3 ? this.puesto : 0,
            idempleado:        this.usuario == null ? 0 : this.usuario.idusuario,
            fecha_inicio:      this.editedItem.fecha_inicio,
            fecha_final:       this.editedItem.fecha_final,
            usuario_registro:  this.editedItem.usuario_registro === 0 ? this.getUsuarioCapa.idusuario : this.editedItem.usuario_registro,
            fecha_creacion:    this.fecha,
            fecha_actualizo:   this.fecha,
            deleted: 0,
          };


          // verifico si ya existe una asignacion con la misma configuracion
          if (this.editedIndex > -1) {
            Object.assign(this.asignaciones[this.editedIndex], this.editedItem);
            this.$http
              .put(
                "asignacion_cursos.update/" + this.editedItem.idasignacion_curso,
                datos
              )
              .then((response) => {
                this.initialize();
              })
              .catch((error) => console.log(error));
          } else {
            this.addCursoEmpleado( datos )
          }
        }else{
          this.existe_asignacion = 'Selecciona las fechas por favor'
        }
      },

      addCursoEmpleado ( datos ) {
        this.$http.post('asignacion_cursos.existe', datos).then((response) => {
          if(response.body.estatus == 1){
            this.existe_asignacion = response.body.message
          }else{
            this.reset()
            this.initialize()
          }
        }).catch((error) => console.log(error));
      }
    },
  };
</script>
