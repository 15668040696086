<template>
  <v-container>
    <!--<player-info-panel :trackInfo="getTrackInfo"> </player-info-panel>-->
    <player-controls-bars
      :loop="loop"
      :shuffle="shuffle"
      :progress="progress"
      :trackInfo="getTrackInfo"
      @playtrack="play"
      @pausetrack="pause"
      @stoptrack="stop"
      @updateseek="setSeek"
    >
    </player-controls-bars>
  </v-container>
</template>

<script>
import PlayerControlsBars from "./components/PlayerControlsBars.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PlayerControlsBars,
  },
  data() {
    return {
      playlist: [
        {
          title: "Audio",
          howl: null,
          display: true,
        },
      ],
      selectedTrack: null,
      index: 0,
      playing: false,
      loop: false,
      shuffle: false,
      seek: 0,
      url: "",
      detener: false,
      audio: "",
    };
  },

  computed: {
    ...mapGetters("Materiales", ["getMaterial", "getStatus"]),

    progress() {
      if (this.audio.duration() === 0) return 0;
      return this.seek / this.audio.duration();
    },
    getTrackInfo() {
      let seek = this.seek,
        duration = this.audio.duration();
      return {
        seek,
        duration,
      };
    },
  },

  watch: {
    playing(playing) {
      this.seek = this.audio.seek();
      let updateSeek;
      if (playing) {
        updateSeek = setInterval(() => {
          this.seek = this.audio.seek();
        }, 250);
      } else {
        clearInterval(updateSeek);
      }
    },

    getMaterial() {
      this.getMaterial;
    },
  },
  created: function() {
    this.url = "https://academicokpi.club/" + "archivo/" + this.getMaterial.url;
    this.audio = new Howl({
      src: [this.url],
      onend: () => {
        this.stop();
      },
    });
  },
  methods: {
    ...mapActions("Materiales", ["guardarMaterial", "guardarStatus"]),

    play() {
      let track = this.audio;

      if (track.playing()) {
        return;
      } else {
        track.play();
      }

      this.playing = true;
    },

    pause() {
      this.audio.pause();
      this.playing = false;
    },
    stop() {
      this.audio.stop();
      this.playing = false;
    },

    setSeek(percents) {
      let track = this.audio;

      if (track.playing()) {
        track.seek((track.duration() / 100) * percents);
      }
    },
  },
};
</script>
