<template>
  <!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="parametros.dialogError"
    persistent
    max-width="400"
  >
    <v-card>
      <v-card-text>
        <v-card class="elevation-0 text-center pt-4">
          <span class="text-headline" style="white-space: pre-line">
            {{ parametros.mensaje }}
          </span>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn :color="parametros.color" dark block @click="parametros.dialogError = false">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
  export default {
    props:[
      'parametros'
    ],
  }
</script>