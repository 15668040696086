<template>
  <v-container>
    <v-data-table :headers="headers" :items="evaluaciones" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Evaluaciones</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nueva evaluación
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-row class="mb-2">
                      <v-col cols="12" sm="8" md="12">
                        <v-text-field
                          class="mt-2"
                          v-model="editedItem.evaluacion"
                          label="Evaluación"
                          :rules="rules"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn color="blue darken-1" text @click="reset">
                  Limpiar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="700px">
            <v-card>
              <v-card-title class="text-h5"
                >¿Estás seguro de eliminar este elemento?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- -->
          <v-dialog v-model="dialogMore" max-width="700px">
            <v-card>
              <v-container>
                <v-card-title class="text-h5"></v-card-title>
                <v-row class="mb-2">
                  <v-col cols="12" sm="8" md="12">
                    <v-text-field
                      v-model="editedItem.evaluacion"
                      label="Evaluación"
                      :rules="rules"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeMore"
                    >Cancelar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-container>
            </v-card>
          </v-dialog>
          <!-- -->
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
              @click="openViewItem(item)"
            >
              <v-icon small>
                mdi-plus-thick
              </v-icon>
            </v-btn>
          </template>
          <span>Agregar preguntas</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
              @click="openItem(item)"
            >
              <v-icon small>
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <span>Vista previa</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
              @click="editItem(item)"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Editar evaluación</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
              @click="deleteItem(item)"
            >
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Eliminar evaluación</span>
        </v-tooltip>
        <!-- -->
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Actualizar
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

var moment = require("moment");
moment.locale();

export default {
  data: () => ({
    rules: [(value) => !!value || "Campo obligatorio."],
    e6: 1,
    subtema: null,
    subtemas: [],
    tipo_evaluacion: null,
    tipo_evaluaciones: [],
    dialog: false,
    dialogDelete: false,
    dialogMore: false,
    headers: [
      {
        text: "Evaluación",
        value: "evaluacion",
      },
      { text: "Tipo Evaluación", value: "idtipo_evaluacion" },
      { text: "Subtema", value: "idsubtema" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    ultimoIdEvaluacion: 0,
    evaluaciones: [], //arreglo
    editedIndex: -1,
    editedItem: {
      //agrego cada campo a utlizar
      idevaluacion: 0,
      evaluacion: "",
      idtipo_evaluacion: 0,
      idsubtema: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    defaultItem: {
      idevaluacion: 0,
      evaluacion: "",
      idtipo_evaluacion: 0,
      idsubtema: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    tipo_pregunta: null,
    tipo_preguntas: [],
    autoevaluable: null,
    autoevaluables: [
      { tipo: "Sí", valor: 1 },
      { tipo: "No", valor: 0 },
    ],
    editedPregunta: {
      idpregunta: 0,
      pregunta: "",
      numero_pregunta: 0,
      valor: 0,
      autoevaluable: 0,
      idevaluacion: 0,
      idtipo_pregunta: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    editedOpcionMultiple: {
      idpregunta_multiple: 0,
      a: "",
      b: "",
      c: "",
      d: "",
      opcion_correcta: "",
      idpregunta: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    habilitar: false,
    iPregunta: 1, //model para generar preguntas
    nueva: [{ n: 1 }],
    valor: 1,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva evaluación" : "Editar evaluación";
    },
    ...mapGetters("Subtemas", ["getIdSubtema"]),
    ...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogMore(val) {
      val || this.closeMore();
    },
    getIdSubtema() {
      this.initialize();
    },
    iPregunta(val) {
      console.log(this.iPregunta + " " + val);
      if (val > this.valor) {
        this.editedPregunta.idpregunta = this.nueva.length + 1;
        this.nueva.push(this.editedPregunta);
      } else {
        this.nueva.splice(this.nueva.length - 1, 1);
      }
      this.valor = val;
    },
    innit() {
      initialize();
    },
    ultimoIdEvaluacion() {
      console.log(this.ultimoIdEvaluacion);
    },
  },

  created() {
    this.initialize();
    // Generando la fecha de hoy
    const fecha = new Date().toLocaleDateString("en-Es", {
      timeZone: "America/Mexico_City",
    });
    this.fecha = moment(fecha)
      .format()
      .substr(0, 10);
  },

  methods: {
    initialize() {
      this.$http
        .get("tipo_evaluacion.all")
        .then((responseTipo) => {
          this.tipo_evaluaciones = responseTipo.body;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$http
        .get("tipo_preguntas.all")
        .then((responseTipo) => {
          this.tipo_preguntas = responseTipo.body;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$http
        .get("evaluaciones.subtemas.all/" + this.getIdSubtema)
        .then((responseEvaluacion) => {
          for (const j in responseEvaluacion.body) {
            responseEvaluacion.body[j].fecha_creacion = responseEvaluacion.body[
              j
            ].fecha_creacion.substr(0, 10);
            responseEvaluacion.body[
              j
            ].fecha_actualizo = responseEvaluacion.body[
              j
            ].fecha_actualizo.substr(0, 10);
          }
          this.evaluaciones = responseEvaluacion.body;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ...mapActions("Subtemas", ["guardarIdSubtema"]),
    ...mapActions("Evaluaciones", ["guardarIdEvaluacion", "guardarEvaluacion"]),

    openViewItem(item) {
      this.guardarIdEvaluacion(item.idevaluacion);
      this.$router.push({ name: "Preguntas" });
    },

    openItem(item) {
      this.guardarEvaluacion(item);
      this.$router.push({ name: "PreviewEvaluacion" });
    },

    editItem(item) {
      this.dialog = true;
      this.editedIndex = this.evaluaciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      this.editedIndex = this.evaluaciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.evaluaciones.splice(this.editedIndex, 1);

      var datos = {
        evaluacion: this.editedItem.evaluacion,
        idtipo_evaluacion: this.editedItem.idtipo_evaluacion,
        idsubtema: this.editedItem.idsubtema,
        fecha_creacion: this.editedItem.fecha_creacion,
        fecha_actualizo: this.fecha,
        usuario_registro: this.editedItem.usuario_registro,
        deleted: 1,
      };

      this.$http
        .put("evaluaciones.update/" + this.editedItem.idevaluacion, datos)
        .then((response) => {
          this.initialize();
        })
        .catch((error) => console.log(error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeMore() {
      this.dialogMore = false;
    },

    save() {
      //recibe los campos del form
      console.log(this.editedItem);
      console.log(this.editedItem.idevaluacion);
      //guardo en una variable los datos a guardar id = this.editedItem.idcurso;

      var datos = {
        evaluacion: this.editedItem.evaluacion,
        idtipo_evaluacion: 3,
        idsubtema: this.getIdSubtema,
        fecha_creacion: this.fecha,
        fecha_actualizo: this.fecha,
        usuario_registro:
          this.editedItem.usuario_registro === 0
            ? this.getUsuarioCapa.idusuario
            : this.editedItem.usuario_registro,
        deleted: 0,
      };

      if (this.editedIndex > -1) {
        this.$http
          .put("evaluaciones.update/" + this.editedItem.idevaluacion, datos)
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));
        Object.assign(this.evaluaciones[this.editedIndex], this.editedItem);
      } else {
        //guardo la evaluacion
        this.$http
          .post("evaluaciones.add", datos)
          .then((response) => {
            this.ultimoIdEvaluacion = response.body.id;
            this.initialize();
          })
          .catch((error) => console.log(error));
        this.evaluaciones.push(this.editedItem);
      }
      this.close();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
