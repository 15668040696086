<template>
  <v-container>
    <v-alert
      class="mx-auto mt-6"
      width="350"
      dense
      v-if="cursos.length === 0"
      text
      prominent    
      icon="mdi-alert-circle-outline"
    >
      Aún no tienes cursos asignados
    </v-alert>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        v-for="(curso, i) in cursos"
        :key="i"
      >
        <v-card>

          <v-img
            class="white--text align-end"
            max-height="250px"
            :src="curso.imagen ? ( url + curso.imagen ) : require('@/assets/sin_foto.jpg')"
            aspect-ratio="2"
          >
            <v-card-title class="primary pa-1" dense v-if="curso.inscripcion == 0">
              <span class="text-subtitle-1"> {{ curso.curso }} </span>
            </v-card-title>
          </v-img>

          <v-card-text class="text--primary">
            <div>
              <strong>Capacitador:</strong> {{ curso.capacitador }}
            </div>

            <div>
              <strong>Duración: </strong><v-icon x-small> mdi-clock-time-two-outline </v-icon> {{ curso.duracion }} hr(s)
            </div>

            <div>
              <strong>Inició el: </strong> {{ curso.fecha_inicio }}
            </div>

            <div>
              <strong>Disponible hasta: </strong> {{ curso.fecha_final }}
            </div>

          </v-card-text>

          <v-card-actions class="text-center mb-2" v-if="curso.inscripcion == 0">
            <v-chip
              v-if="curso.avance < 100 && curso.inscripcion == 0"
              color="red"
              dark
              rounded
              @click="openCurso(curso)"
            >
              Empezar curso
            </v-chip>

            <v-btn
              v-else-if="curso.avance >= 100 && curso.inscripcion == 0"
              color="green"
              text
              @click="openCurso(curso)"
            >
              Curso completo
            </v-btn>

            <v-spacer></v-spacer>

            <v-chip
              v-if="curso.avance >= 100"
              class="ma-2"
              color="success"
              outlined
            >
              <v-icon left> mdi-check-decagram </v-icon>Curso completo
            </v-chip>
          </v-card-actions>


          <v-card-actions class="text-center mb-2" v-if="curso.inscripcion == 1">
            <v-chip
              v-if="curso.inscrito && curso.avance < 100 "
              color="red"
              dark
              rounded
              @click="openCurso(curso)"
            >
              Empezar curso
            </v-chip>

            <v-btn
              v-else-if="curso.avance >= 100 && curso.inscrito"
              color="green"
              text
              @click="openCurso(curso)"
            >
              Curso completo
            </v-btn>

            <v-btn
              v-else
              color="green"
              block
              tile
              dark
              @click="inscribirme(curso)"
            >
              INSCRIBIRME
            </v-btn>
          </v-card-actions>

          <div class="pa-2">
            <v-progress-linear
              height="20"
              :value="curso.avance"
              dark
              color="green"
              style="border-radius: 25px;"
              rounded
            >
              <strong class="text-caption">{{ curso.avance }}% de avance</strong>
            </v-progress-linear>
          </div>

        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
  var moment = require("moment");
  moment.locale();

  import { mapActions, mapGetters } from "vuex";

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],


    data: () => ({

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      cursos: [], //arreglo
      //evaluacion
      evaluaciones: [],
    }),

    computed: {
      ...mapGetters("Cursos", ["getIdCurso"], ["getCurso"]), //cambios

      ...mapGetters("Materiales", ["getMaterial"]),

      ...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),
    },

    watch: {},

    created() {
      console.log( this.getUsuarioCapa )
      this.initialize();
      this.url = "https://academicokpi.club/" + "capacitacion-imagenes/";
    },

    methods: {
      initialize() {
        this.cargar = true
        // console.log(this.getUsuarioCapa.idusuario);
        
        //llamo a la api para obtener todos los registros
        this.$http.get("curso_empleado.usuario/" + this.getUsuarioCapa.idusuario).then((response) => {
          // al arreglo que declare previamente le asigno response.body que son todos los registros
          this.cursos = response.body;

          this.cargar = false
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      ...mapActions("Cursos", ["guardarIdCurso", "guardarCurso"]), ///cambios

      ...mapActions("Materiales", ["guardarMaterial"]),

      openCurso(item) {
        this.guardarCurso(item); //cambios
        this.$router.push({ name: "Curso" });
      },

      inscribirme( curso ){
        this.cargar = true
        
        //llamo a la api para obtener todos los registros
        this.$http.post("inscripcion.curso", { iderp: this.getUsuarioCapa.id_usuario, idcurso: curso.idcurso }).then((response) => {
          // al arreglo que declare previamente le asigno response.body que son todos los registros
          this.initialize()

          this.cargar = false
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      }
    },
  };
</script>
