import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

export default {
    namespaced: true,
    state: {
        stop: false,
    },

    mutations: {
        STOP(state, stop) {
            state.stop = stop
        }
    },

    actions: {
        guardarStop({ commit, dispatch }, stop) {
            commit('STOP', stop)
            console.log("stop:  " + stop)
        }
    },

    getters: {
        getStop(state) {
            return state.stop
        },
    }
}