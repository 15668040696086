<template>
	<v-container fluid>
    <v-textarea
      v-model="mensaje"
      label="Mensaje"
      filled
      hide-details
      dense
      readonly
    ></v-textarea>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

	// Componentes
  export default {

  	components:{
      Alerta,
      carga,
    },

  	props:[
			'materialItem'
	  ],
    data: () => ({
      // Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      mensaje:'',
    }),

    computed: {
    	...mapGetters( 'UsuarioCapa', ['getUsuarioCapa']),
    },

    methods: {
    	save () {
	      this.cargar = true
        // Lo mandapos por el EP
        this.$http.post('grupos.add', this.editedItem).then(response=>{
        	this.validarSuccess( response.body.message )
        	this.$emit('click')
        	this.initialize()
        }).catch( error =>{
        	this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},
    },
  }
</script>


