<template>
	<!-- Dialogo para indicar errores -->
  <v-dialog
    persistent
    max-width="800"
    v-model="parametros.estatus"
  >
		<v-container fluid>
		  <v-row>
		  	<v-col cols="12" class="pa-0">
		  		<v-card class="mx-auto shadowCard" >
				    <v-card-title class="text-h6 font-weight-regular justify-space-between">
				      <span class="text-subtitle-1"> Cursos </span>
				    </v-card-title>
				    <v-card-text>
				      <v-data-table
	              v-if="parametros.cursos"
	              :headers="headers"
	              :items="parametros.cursos"
	              dense
	              multi-sort
	              class="elevation-0 mt-4"
	              show-expand
	              :expanded.sync="expanded"
	              :single-expand="true"
	              item-key="curso"
            	>
	              <template v-slot:item.calificacion="{ item }">
	                {{ item.calificacion.toFixed( 2 ) }}
	              </template>

	              <template v-slot:item.estatus="{ item }">
	                <v-chip v-if="item.estatus" color="success" dark small>Completado</v-chip>
	                <v-chip v-else              color="red"     dark small>Pendiente</v-chip>
	              </template>

	              <template v-slot:expanded-item="{ headers, item }">
	              	<td :colspan="headers.length">
		              	<v-card width="100%" class="elevation-0 custom-card ma-1" >
	              	  	<v-card-title primary-title class="text-subtitle-1 pb-0">
	              	  	  Calificaciones por evaluación
	              	  	</v-card-title>
		              	  <v-card-text class="pt-0 pl-0 pr-0">
		              	    <v-data-table
						              v-if="item.evaluaciones.length"
						              :headers="headers2"
						              :items="item.evaluaciones"
						              dense
						              class="elevation-0 mt-4"
						              hide-default-footer
						              fixed-header
						              :items-per-page="item.evaluaciones.length"
					            	>
						              <template v-slot:item.calificacion="{ item }">
						                {{ item.calificacion.toFixed( 2 ) }}
						              </template>

						            </v-data-table>

						            <v-alert
										      dense
										      outlined
										      type="error"
										      v-else
										    >
										      Sin evaluaciones
										    </v-alert>

		              	  </v-card-text>
		              	</v-card>
	              	</td>
	              </template>

	            </v-data-table>
				    </v-card-text>
				    <v-card-actions>
				      <v-spacer></v-spacer>
				      <v-btn @click="parametros.estatus = false">Cerrar</v-btn>
				    </v-card-actions>
				  </v-card>
		  	</v-col>
		  </v-row>
		</v-container>
	</v-dialog>
</template>
<script>
	import { mapGetters } from 'vuex'
	// Componentes

  export default {
  	props:[
			'parametros'
	  ],

	  data: () => ({
      headers: [
        { text: "Curso"         , value: "curso"  },
        { text: "Calificación"  , value: "calificacion"      },
        { text: "Estatus"       , value: "estatus"           },
        { text: 'Evaluaciones'  , value: 'data-table-expand' },
      ],

      headers2: [
        { text: "Tema"          , value: "tema"           },
        { text: "Subtema"       , value: "subtema"        },
        { text: "Evaluación"    , value: "evaluacion"     },
        { text: 'Calificación'  , value: 'calificacion'   },
        { text: 'Intentos'      , value: 'numero_intento' },
      ],

      expanded: [],
    }),
  }
</script>

<style scoped>
	.custom-card {
 	  border-radius: 0;
 	  width: 100%;
	}
</style>
