<template>
  <v-container class="fill-height">
  
    <v-row align="center" justify="center" class="align-end" align-content="center">
      <v-col cols="12" md="6" lg="4" xl="3" align="center" justify="center">
        <v-card class="elevation-10 shadowCard">
          <v-card-title primary-title>
            <v-img src="@/assets/logo.png"></v-img>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider class="ma-4"></v-divider>
          <v-form 
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-card-text class="pb-0">
              <v-text-field
                dense
                prepend-inner-icon="mdi-account"
                label="Usuario"
                v-model="email"
                clearable
                dense
                :rules="[v => !!v || 'Item is required']"
                filled
                rounded
              ></v-text-field>
              <v-text-field
                dense
                prepend-inner-icon="mdi-lock"
                label="Contraseña"
                v-model="password"
                clearable
                dense
                :rules="[v => !!v || 'Item is required']"
                filled
                rounded
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                @keyup.enter="validate()"
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="px-4 pb-6">
              <v-btn rounded block class="btn-login" @click="validate()" dark>Iniciar sesión</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>


<script>
  // import router from '@/router'
  import {mapActions , mapGetters} from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,


      acceso: false,
      nombre_completo: "",
      cargo: "",
      datos_usuario: {},

      show1: false,
      error: '',

      password: '',
      email:'',

      value:'',

      valid: true,
      rules: {
        required: value => !!value || 'Requerido.'
      },
      showPassword: false,

    }),

    computed:{
      ...mapGetters('UsuarioCapa',['getUsuarioCapa', 'getLogeadoCapa']),
    },

    created(){
      this.initialize()
    },

    
    methods:{
      ...mapActions('UsuarioCapa',['guardarInfo','salirLogin']),

      initialize() {
        if(this.$route.params.id){
          this.cargar = true
          // Aquí se envían los datos del usuario para saber que perfil tiene
          this.$http.get('usuarios.id.info/' + this.$route.params.id).then((response) => {
            this.datos_usuario    = response.body;
            this.acceso           = true
            this.nombre_completo  = response.body.nombre_completo
            this.cargar = false
            this.guardarInfo( response.body )
            this.$router.push({name: 'MisCursos'})
          }).catch( error =>{
            this.cargar = false
            this.acceso = false
            this.validarError( error )
          }).finally( () => { this.cargar = false })
        }
      },


      validate () {
        if(this.$refs.form.validate()){
          this.cargar = true

          var usuario = { email : this.email, password: this.password}
          this.$http.post('usuarios.data',usuario).then((response) => {
            if(response.body.length > 0){
              this.cargar = false
              this.acceso = true
              this.datos_usuario = response.body[0]
              this.guardarInfo(this.datos_usuario)
              this.$router.push({name: 'MisCursos'})
            }else{
              this.validarErrorDirecto('Usuario no existe')
            }
          }).catch( error =>{
            this.validarError( error )
          }).finally( () => { this.cargar = false })
        }
      }
 
    }
  }
</script>

<style scoped>
  
  
</style>


  