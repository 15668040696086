import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VueResource from 'vue-resource'

import { Howl, Howler } from 'howler'

Vue.config.productionTip = false
Vue.use(VueResource)

if(process.env.NODE_ENV == 'development'){
  Vue.http.options.root = 'http://localhost:3004/';
  // Vue.http.options.root = 'https://web-back-inbi.club/kpi/'
}else{
  Vue.http.options.root = process.env.VUE_APP_RUTA_API;
}

//player
Vue.filter('numbers', (value) => {
  let number = value + 1
  if (number < 10) {
    return "0" + number + "."
  }
  return number + "."
})

Vue.filter('minutes', (value) => {
  if (!value || typeof value !== "number") return "00:00"
  let min = parseInt(value / 60),
      sec = parseInt(value % 60)
  min = min < 10 ? "0" + min : min
  sec = sec < 10 ? "0" + sec : sec
  value = min + ":" + sec
  return value
})
///

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
