import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

export default {
    namespaced: true,
    state: {
        idEvaluacion: '',
        evaluacion: {
            idevaluacion: 0,
            evaluacion: "",
            idtipo_evaluacion: 0,
            idsubtema: 0,
            usuario_registro: 0,
            fecha_creacion: null,
            fecha_actualizo: null,
            deleted: 0,
        },
    },

    mutations: {
        ID_EVALUACION(state, idEvaluacion) {
            state.idEvaluacion = idEvaluacion
        },
        EVALUACION(state, evaluacion) {
            state.evaluacion = evaluacion
        },
    },

    actions: {
        guardarIdEvaluacion({ commit, dispatch }, id) {
            commit('ID_EVALUACION', id)
            console.log(id + " Evaluacion")
        },
        guardarEvaluacion({ commit, dispatch }, item) {
            commit('EVALUACION', item)
            console.log("Evalaucion: ")
            console.log(item)
        },
    },

    getters: {
        getIdEvaluacion(state) {
            return state.idEvaluacion
        },
        getEvaluacion(state) {
            return state.evaluacion
        },
    }
}