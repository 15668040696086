<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>

            <span class="text-subtitle-1">Comentarios</span>
            <v-spacer></v-spacer>

            <v-btn color="green" dark class="mr-2" @click="exportExcel(filterComentarios, 'notas_capacitacion')" small rounded>
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn color="primary" @click="initialize( )" small rounded>
              Actualizar
            </v-btn>

          </v-card-title>

          <v-card-text>
            <v-row justify="start">
              <v-col cols="12" md="6" lg="4">
                <v-autocomplete
                  :items="empleadosFinales"
                  v-model="empleado"
                  label="Empleado"
                  item-text="nombre_completo"
                  item-value="iderp"
                  filled
                  hide-details
                  dense
                  single-line
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-autocomplete
                  :items="cursosFinales"
                  v-model="curso"
                  label="Curso"
                  item-text="curso"
                  item-value="idcurso"
                  filled
                  hide-details
                  dense
                  single-line
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>


            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headersComentarios"
              :items="filterComentarios"
              :search="search"
              dense
              multi-sort
              class="elevation-0 mt-4"
              show-expand
              single-expand
    					:expanded.sync="expanded"
    					item-key="idnotas_capacitacion"
            >

              <template v-slot:item.nota="{ item }">
                {{ item.nota.length > 100 ? item.nota.substr( 0, 100 ) + '...' : item.nota }}
              </template>

              <template v-slot:expanded-item="{ headers, item }">
					      <td :colspan="headers.length" class="pa-6">
					      	<v-textarea
					      		v-model="item.nota"
					      		auto-grow
					      		outlined
					      		readonly
					      	>
					      	</v-textarea>
					      </td>
					    </template>
              
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                  Actualizar
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>

<script>
 // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores   from '@/mixins/validarErrores'
  import funcionesExcel   from '@/mixins/funcionesExcel'


  export default {

    mixins:[ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      ///usuarios
      usuario: null,
      usuarios: [],
      evaluacion: null,
      evaluaciones: [],
      dialog: false,
      dialogDelete: false,
      search: "",
      headersComentarios: [
        { text: "ID"        , value: "iderp"           },
        { text: "Empleado"  , value: "nombre_completo" },
        { text: "Curso"     , value: "curso"           },
        { text: "Tema"      , value: "tema"            },
        { text: "Subtema"   , value: "subtema"         },
        { text: "Material"  , value: "material"        },
        { text: "Nota"      , value: "nota"            },
      ],
      comentariosUsuarios:[],
			empleadosFinales:[],
			cursosFinales:[],
      editedIndex: -1,
      empleado:null,
      curso: null,
      puesto:null,
      estatus:3,

      ver: false,
      parametros:{
        cursos: [],
        estatus: false
      },
      expanded:[]
    }),

    computed: {
      formTitle() {
        return this.editedIndex === -1 ? "Nuevo curso" : "Editar curso";
      },

      filterComentarios( ){
      	// Guardamos para poder hacer el filtro completo
      	let comentario = this.comentariosUsuarios

      	// Validamos si hay un empleado seleccionado
      	if( this.empleado ){

      		// filtramos por ese usuario 
      		comentario = comentario.filter( el => { return el.iderp == this.empleado })
      	}

      	// Validamos existe seleccionado un curso
      	if( this.curso ){

      		// Filtramos por ese curso también 
      		comentario = comentario.filter( el => { return el.idcurso == this.curso })
      	}


      	return comentario


      },
    },

    watch: {
      dialog(val) {
        val || this.close();
      },
    },

    async created() {
      this.initialize();
      // Generando la fecha de hoy
    },

    methods: {
      initialize() {
        this.comentariosUsuarios = [];
        this.empleadosFinales    = [];
        this.cursosFinales       = [];
        this.cargar = true

        //llamo a la api para obtener todos los registros
        this.$http.get("materiales.comentarios").then((response) => {
          // al arreglo que declare previamente le asigno response.body que son todos los registros
          this.cargar = false
          this.comentariosUsuarios = response.body.comentariosUsuarios
          this.empleadosFinales    = response.body.empleadosFinales
          this.cursosFinales       = response.body.cursosFinales
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      editItem(item) {
        this.editedIndex = this.calificaciones.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      verCursos( value ){
        this.parametros.cursos  = value
        this.parametros.estatus = true
        this.ver = true
      }
    },
  };
</script>
