<template>
  <v-container>

    <v-card class="shadowCard">
      <v-card-title primary-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="dialog = true" small>
          Nuevo material
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="materiales"
          sort-by="calories"
          class="elevation-0"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="400px">
              <v-card>
                <v-card-title>
                  <span class="text-h6">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="form" lazy-validation>
                      <v-row>
                        <!-- tipo material add -->
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.idtipo_material"
                            :items="tipo_materiales"
                            label="Selecciona un tipo de material"
                            item-text="tipo_material"
                            item-value="idtipo_material"
                            filled
                            dense
                            hide-details
                          >
                          </v-select>
                        </v-col>

                        <!-- subir ARCHIVO  -->
                        <v-col cols="12">
                          <v-file-input
                            @change="cargarMaterial()"
                            v-model="files"
                            label="Material"
                            prepend-inner-icon="mdi-file"
                            :accept="acepta"
                            persistent-hint
                            :hint="editedItem.url"
                            filled
                            dense
                          ></v-file-input>
                        </v-col>

                        <!-- nombre del material -->
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.nombre"
                            label="Nombre del material"
                            filled
                            dense
                            hide-details
                          >
                          </v-text-field>
                        </v-col>

                        <!-- mostrar archivo  -->
                        <v-col cols="12">
                          <!-- editars    -->
                          <div v-if="editedItem.url != ''">

                            <!-- Videos -->
                            <video
                              v-if="editedItem.idtipo_material == 1"
                              height="300"
                              width="100%"
                              controls
                              controlslist="nodownload"
                              :src="linkVideos + editedItem.url"
                            ></video>

                            <!-- <AudioPlayer  />-->
                            <div v-if="editedItem.idtipo_material == 2">
                              <v-card>
                                <v-progress-linear
                                  class="mt-2"
                                  height="5"
                                  v-model="trackProgress"
                                  @click="updateSeek($event)"
                                ></v-progress-linear>
                                <!-- Info player -->
                                <div
                                  class="d-flex flex-no-wrap justify-space-between"
                                >
                                  <div>
                                    <v-card-title
                                      ><h3>
                                        {{ editedItem.url.replace(".mp3", "") }}
                                      </h3>
                                    </v-card-title>
                                    <v-container>
                                      <h5 dense>
                                        {{ getTrackInfo.seek | minutes }}/{{
                                          getTrackInfo.duration | minutes
                                        }}
                                      </h5>

                                      <v-card-actions>
                                        <v-btn
                                          v-if="play == false"
                                          outlined
                                          fab
                                          small
                                          color="light-blue"
                                          @click="playTrack()"
                                        >
                                          <v-icon large>mdi-play</v-icon>
                                        </v-btn>
                                        <v-btn
                                          v-else
                                          outlined
                                          fab
                                          small
                                          color="light-blue"
                                          @click="pauseTrack()"
                                        >
                                          <v-icon large>mdi-pause</v-icon>
                                        </v-btn>
                                      </v-card-actions>
                                    </v-container>
                                  </div>

                                  <v-avatar class="ma-3" size="125" tile>
                                    <v-icon large>mdi-headphones</v-icon>
                                  </v-avatar>
                                </div>
                              </v-card>
                            </div>

                            <!-- Imágenes  />-->
                            <v-img
                              contain
                              :src="linkImagenes + editedItem.url"
                              class="white--text align-end"
                              height="300"
                              width="100%"
                              v-if="editedItem.idtipo_material == 3"
                            >
                            </v-img>

                            <embed
                              id="pdf"
                              type="application/pdf"
                              v-if="editedItem.idtipo_material == 4"
                              :src="linkArchivos + editedItem.url + '#toolbar=0'"
                              height="300"
                              width="100%"
                            />

                            <iframe 
                              v-if="editedItem.idtipo_material == 6"
                              :src="`https://view.officeapps.live.com/op/embed.aspx?wdPrint=0&zoom=0&src=${linkArchivos + editedItem.url}`"
                              width="100%" 
                              height="300"
                              frameborder='0'
                              sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts" 
                              scrolling="no" 
                              style="border: none; max-width: 100%; max-height: 100vh" 
                              allowfullscreen 
                              mozallowfullscreen 
                              msallowfullscreen 
                              webkitallowfullscreen
                            ></iframe>

                          </div>

                          <!-- crearss  -->
                          <div v-if="files">
                            <video
                              v-if="editedItem.idtipo_material == 1"
                              height="300"
                              width="100%"
                              controls
                              controlslist="nodownload"
                            >
                              <source :src="archivo" type="video/mp4" />
                            </video>

                            <v-img
                              contain
                              :src="archivo"
                              class="white--text align-end"
                              height="300"
                              width="100%"
                              v-if="editedItem.idtipo_material == 3"
                            >
                            </v-img>

                            <v-card v-else-if="editedItem.idtipo_material == 2">
                              <div
                                class="d-flex flex-no-wrap justify-space-between"
                              >
                                <v-card-title>
                                  <h4>
                                    {{ files.name }}
                                  </h4>
                                </v-card-title>

                                <v-avatar
                                  color="primary"
                                  class="ma-3"
                                  size="75"
                                  tile
                                >
                                  <v-icon
                                    color="white"
                                    large
                                    v-if="editedItem.idtipo_material == 2"
                                    >mdi-headphones</v-icon
                                  >
                                  <v-icon color="white" large v-else
                                    >mdi-file-pdf</v-icon
                                  >
                                </v-avatar>
                              </div>
                            </v-card>

                            <embed
                              type="application/pdf"
                              v-if="editedItem.idtipo_material == 4"
                              :src="archivo"
                              height="600"
                              width="100%"
                            />
                            <div v-else></div>
                          </div>

                          <div v-else></div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-btn color="red" dark @click="close" rounded small>
                    Cancelar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="save" rounded small>
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >¿Estás seguro de eliminar este elemento?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="editItem(item)"
                >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Editar material</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="deleteItem(item)"
                >
                  <v-icon small>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar material</span>
            </v-tooltip>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Actualizar
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>

<script>
import axios from 'axios';

  import { mapActions, mapGetters } from "vuex";
  import AudioPlayer from "../player/AudioPlayer.vue";

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

var moment = require("moment");
moment.locale();

export default {

  components:{
    Alerta,
    carga,
    AudioPlayer
  },

  data: () => ({
    // Alertas
    parametros:{
      dialogError: false,
      mensaje: '',
      color: ''
    },

    respuestaAlerta:false,
    loader: true,
    cargar: false,

    //variables utilizadas para subir imgs
    files: null,
    formdata: "",
    archivo: "",
    link: "",
    acepta: "",
    ///
    subtema: null,
    subtemas: [],
    tipo_material: null,
    tipo_materiales: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Nombre"        , value: "nombre"       , },
      { text: "URL"           , value: "url"          , },
      { text: "Tipo Material" , value: "tipo_material", },
      { text: "Subtema"       , value: "subtema"      , },
      { text: "Actions"       , value: "actions", sortable: false, },
    ],
    materiales: [], //arreglo
    editedIndex: -1,
    editedItem: {
      //agrego cada campo a utlizar
      idmaterial: 0,
      idtipo_material: 0,
      idsubtema: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
      url:'',
      extension:'',
      tipo_material: "",
    },
    defaultItem: {
      idmaterial: 0,
      idtipo_material: 0,
      idsubtema: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
      url:'',
      extension:'',
      tipo_material: "",
    },
    //**---audio---**//
    play: false,
    url: "",
    playing: false,
    seek: 0,
    progress: 0,
    duration: 0,
    nombre_archivo:'',

    linkVideos:'',
    linkImagenes:'',
    linkArchivos:''
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo material" : "Editar material";
    },
    ...mapGetters("Subtemas", ["getIdSubtema"]),
    ...mapGetters("Materiales", ["getMaterial", "getStatus"]),
    ...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),

    //**---audio---**//

    trackProgress() {
      this.progress = this.seek / this.audio.duration();
      return this.progress * 100;
    },

    getTrackInfo() {
      let seek = this.seek,
        duration = this.audio.duration();
      return {
        seek,
        duration,
      };
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    getIdSubtema() {
      this.initialize();
    },
    
    archivo() {
      this.archivo;
    },

    'editedItem.idtipo_material'() {
      switch (this.editedItem.idtipo_material) {
        case 1:
          this.acepta = "video/*";
        break;
        case 2:
          this.acepta = "audio/*";
        break;
        case 3:
          this.acepta = "image/*";
        break;
        case 4:
          this.acepta = ".pdf";
        break;
        case 6:
          this.acepta = ".ppt, .pptx";
        break;
      }
    },

    editedItem() {
      //**---audio---**//
      if (this.editedItem.idmaterial != 0) {
        // Tipo de material audio
        if( this.editedItem.idtipo_material == 2 ){
          this.url = "https://academicokpi.club/" + "capacitacion-audios/" + this.editedItem.url;
          this.audio = new Howl({
            src: [this.url],
            onend: () => {
              this.stopTrack();
              this.play = false;
            },
          });
        }
      }
    },

    //**---audio---**//
    playing(playing) {
      this.seek = this.audio.seek();
      let updateSeek;
      if (playing) {
        updateSeek = setInterval(() => {
          this.seek = this.audio.seek();
        }, 250);
      } else {
        clearInterval(updateSeek);
      }
    },
  },

  created() {
    this.initialize();

    this.linkVideos   = "https://academicokpi.club/" + "capacitacion-videos/";
    this.linkImagenes = "https://academicokpi.club/" + "capacitacion-imagenes/";
    this.linkArchivos = "https://academicokpi.club/" + "capacitacion-archivos/";
  },

  methods: {
    ...mapActions("Subtemas", ["guardarIdSubtema"]),
    ...mapActions("Materiales", ["guardarMaterial", "guardarStatus"]),

    async initialize() {
      await this.getTipoMaterial()
      await this.getMaterialSubtema()
    },

    getTipoMaterial() {
      this.cargar = true
      this.tipo_materiales = [];

      this.$http.get("tipo_materiales.all").then((responseTipo) => {
        this.cargar = false
        this.tipo_materiales = responseTipo.body;
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    getMaterialSubtema() {
      this.cargar = true
      this.materiales = [];
      this.$http.get("materiales.subtemas.all/" + this.getIdSubtema).then((responseMateriales) => {
        this.cargar = false
        this.materiales = responseMateriales.body;
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    goBack() {
      window.history.back();
    },

    editItem(item) {
      this.tipo_material = item.idtipo_material;
      this.editedIndex = this.materiales.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.materiales.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.cargar = true
      this.$http.put("materiales.deleted/" + this.editedItem.idmaterial).then((response) => {
        this.cargar = false
        this.initialize();
        this.closeDelete();
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })

    },

    close() {
      this.dialog = false;
      this.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      
      if (this.editedIndex > -1) {
        this.$http.put("materiales.update/" + this.editedItem.idmaterial, datos).then((response) => {
          if (this.formdata != "") {
            //esta api es para subir el archivo al serverthis.archivo
            this.$http.post("archivos", this.formdata).then((response) => {
              this.reset();
            }).catch( error =>{
              this.validarError( error )
            }).finally( () => { this.cargar = false })
          }

          Object.assign(this.materiales[this.editedIndex], this.editedItem);
          this.initialize();
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      } else {

        // Validar que todo este bien cargardo, datos y todo 
        if( !this.files ){
          this.validarErrorDirecto( 'Agrega un material por favor ')
          return
        }

        if( !this.editedItem.nombre || !this.editedItem.idtipo_material ){
          this.validarErrorDirecto( 'Llena todos los datos por favor ')
          return
        }

        this.cargar = true
        // Guardamos primero el material 
        const saveMaterial = await this.saveMaterial( this.files, this.editedItem.idtipo_material ).then( response=> response )

        // Agregamos los datos extra que necesitamos
        this.editedItem.url              = saveMaterial.nombre
        this.editedItem.extension        = saveMaterial.extension
        this.editedItem.usuario_registro = this.getUsuarioCapa.iderp
        this.editedItem.idsubtema        = this.getIdSubtema

        // Agregar un material
        this.$http.post("materiales.add", this.editedItem).then((response) => {
          this.cargar = false

          this.close();
          this.reset();
          this.getMaterialSubtema();
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })

      //   this.materiales.push(this.editedItem);
      }
    },

    saveMaterial( archivo, tipo_material ){
      return new Promise((resolve,reject)=>{
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", archivo);
        // this.$http.post("capacitacion.materiale.add/" + tipo_material, formData).then( response => {
        //   resolve( response.body ) 
        // }).catch( error =>{
        //   this.validarError( error )
        // }).finally( () => { this.cargar = false })

        return axios.post("https://academicokpi.club/kpi/capacitacion.materiale.add/" + tipo_material, formData).then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          return reject(error);
        })
        .finally(() => {
          this.cargar = false;
        });

      })
    },

    reset() {
      //**---audio---**//
      if( this.tipo_material == 2 ) {
        this.stopTrack();
      }

      this.archivo = "";
      this.files = [];
      this.editedItem.url = "";
      this.tipo_material = null;
      this.editedItem.idtipo_material = null;
      this.$refs.form.reset();

      this.play = false;
    },

    cargarMaterial() {
      if ( this.files && this.files.name != undefined) {
        // creamos una variable tipo FormData
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", this.files);
        //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
        this.getBase64(this.files, formData);
      }
    },

    getBase64(file, formData) {
      var me = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        me.archivo = reader.result; //base64
        me.formdata = formData; //archivo
      };
    },

    //**---audio---**//
    playTrack() {
      this.audio.play();
      this.play = true;
      this.playing = true;
    },

    pauseTrack() {
      this.play = false;
      this.audio.pause();
      this.playing = false;
    },

    stopTrack() {
      this.audio.stop();
      this.playing = false;
    },

    //linear progress bar
    updateSeek(event) {
      if (this.audio.playing()) {
        this.audio.seek((this.audio.duration() / 100) * percents);
      }

      let el = document.querySelector(".progress-linear__bar"),
        mousePos = event.offsetX,
        elWidth = el.clientWidth,
        percents = (mousePos / elWidth) * 100;
    },

    showPdf() {
      window.open(this.linkArchivos + this.editedItem.url, "_blank");
    },

    validarSuccess( mensaje ){
      this.cargar = false
      this.parametros.mensaje      = mensaje
      this.parametros.dialogError  = true 
      this.parametros.color        = 'success'
      this.respuestaAlerta         = true 
    },

    validarError ( value ) {
      this.cargar = false 
      if( value.status == 400 || value.status == 500){
        this.parametros.mensaje      = value.body.message
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
      }
    },

    validarErrorDirecto ( mensaje ) {
      this.cargar = false 
      this.parametros.mensaje      = mensaje
      this.parametros.dialogError  = true 
      this.parametros.color        = '#FF2C2C'
      this.respuestaAlerta = true 
    },
  },
};
</script>
