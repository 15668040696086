<template>
  <v-container>
    <v-card class="shadowCard">
      <v-card-title primary-title>
        <v-btn
          class="mr-2"
          small
          icon
          color="primary" @click="goBack()"
        >
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>

        Temas
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="dialog = true" small>
          Nuevo tema
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row justify="end">
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              filled
              hide-details
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :search="search"
          dense
          multi-sort
          :headers="headers"
          :items="temas"
          class="elevation-0"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="8" md="12">
                            <v-text-field
                              :rules="rules"
                              v-model="editedItem.tema"
                              label="Tema"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="reset">
                      Limpiar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Estás seguro de eliminar este elemento?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="openViewItem(item)"
                >
                  <v-icon small>
                    mdi-plus-thick
                  </v-icon>
                </v-btn>
              </template>
              <span>Agregar subtema</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="editItem(item)"
                >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Editar tema</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="deleteItem(item)"
                >
                  <v-icon small>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar tema</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Actualizar
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
  
</template>

<script>
var moment = require("moment");
moment.locale();

import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    rules: [(value) => !!value || "Campo obligatorio."],
    curso: null,
    cursos: [],
    dialog: false,
    dialogDelete: false,
    search: "",
    headers: [
      { text: "Tema"    , value: "tema"   , sortable: true  , width: '40%' },
      { text: "Curso"   , value: "curso"  , sortable: true  , width: '40%' },
      { text: "Actions" , value: "actions", sortable: false , width: '20%' },
    ],
    temas: [], //arreglo
    editedIndex: -1,
    editedItem: {
      //agrego cada campo a utlizar
      idtema: 0,
      tema: "",
      idcurso: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
    defaultItem: {
      idtema: 0,
      tema: "",
      idcurso: 0,
      usuario_registro: 0,
      fecha_creacion: null,
      fecha_actualizo: null,
      deleted: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo tema" : "Editar tema";
    },
    ...mapGetters("Cursos", ["getIdCurso"]),
    ...mapGetters("Temas", ["getIdTema"]),
    ...mapGetters("UsuarioCapa", ["getUsuarioCapa", "getLogeadoCapa"]),

    //						modulos  getter
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    getIdCurso() {
      this.initialize();
    },
  },

  created() {
    this.initialize();
    // Generando la fecha de hoy
    const fecha = new Date().toLocaleDateString("en-Es", {
      timeZone: "America/Mexico_City",
    });
    this.fecha = moment(fecha)
      .format()
      .substr(0, 10);
  },

  methods: {
    initialize() {
      this.$http.get("temas.cursos.all/" + this.getIdCurso).then((responsetemas) => {
        console.log( responsetemas.body )
        this.temas = responsetemas.body;
      })
      .catch((error) => {
        console.log(error);
      });

      this.cursos = [];
      this.$http
        .get("cursos.all")
        .then((responseTipo) => {
          this.cursos = responseTipo.body;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    ...mapActions("Temas", ["guardarIdTema"]),

    openViewItem(item) {
      this.guardarIdTema(item.idtema);
      this.$router.push({ name: "Subtemas" });
    },

    editItem(item) {
      this.editedIndex = this.temas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.temas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.temas.splice(this.editedIndex, 1);
      //"Borrando"
      var datos = {
        tema: this.editedItem.tema,
        idcurso: this.editedItem.idcurso,
        fecha_creacion: this.editedItem.fecha_creacion,
        fecha_actualizo: this.fecha,
        usuario_registro: this.getUsuarioCapa.idusuario,
        deleted: 1,
      };
      this.$http
        .put("temas.update/" + this.editedItem.idtema, datos)
        .then((response) => {
          this.initialize();
        })
        .catch((error) => console.log(error));

      console.log(this.editedItem.idtema + " borrar");
      this.closeDelete();
    },
    goBack() {
      window.history.back();
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      //recibe los campos del form
      console.log(this.editedItem);

      //guardo en una variable los datos a guardar id = this.editedItem.idcurso;
      var datos = {
        tema: this.editedItem.tema,
        idcurso: this.getIdCurso,
        usuario_registro:
          this.editedItem.usuario_registro === 0
            ? this.getUsuarioCapa.idusuario
            : this.editedItem.usuario_registro,
        fecha_creacion:
          this.editedItem.fecha_creacion === null
            ? this.fecha
            : this.editedItem.fecha_creacion,
        fecha_actualizo: this.fecha,
        deleted: 0,
      };

      if (this.editedIndex > -1) {
        this.$http
          .put("temas.update/" + this.editedItem.idtema, datos)
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));
        Object.assign(this.temas[this.editedIndex], this.editedItem);
      } else {
        this.$http
          .post("temas.add", datos)
          .then((response) => {
            this.initialize();
          })
          .catch((error) => console.log(error));

        this.temas.push(this.editedItem);
      }
      this.close();
    },

    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
