import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

export default {
    namespaced: true,
    state: {
        idPregunta: '',
    },

    mutations: {
        ID_PREGUNTA(state, idPregunta) {
            state.idPregunta = idPregunta
        },
    },

    actions: {
        guardarIdPregunta({ commit, dispatch }, id) {
            commit('ID_PREGUNTA', id)
            console.log(id + " Pregunta")
        },
    },

    getters: {
        getIdPregunta(state) {
            return state.idPregunta
        },

    }
}